import { Image, useTheme } from "@aws-amplify/ui-react";

import logo from './ivslogo.png'; // with import

import './styles.css';

export function Header() {
  const { tokens } = useTheme();

  return (
    <Image
      alt="logo"
      src={logo}
      padding={tokens.space.small}
    />
  );
}
