import React, { useState, useEffect , Component, createContext,  useReducer } from "react";
import ReactDom from 'react-dom';

import Modal from 'react-bootstrap/Modal';
//import Button from 'react-bootstrap/Button';

import { Alert } from '@aws-amplify/ui-react';

import { Auth, Amplify, API, DataStore, graphqlOperation } from 'aws-amplify'
import { withAuthenticator, useAuthenticator, useTheme } from "@aws-amplify/ui-react";

//import { Flex, Link } from "@aws-amplify/ui-react";

import config from "./aws-exports";


import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet
} from "react-router-dom";

import {
  createBrowserRouter,
  RouterProvider,
  useSearchParams,

} from "react-router-dom";

import { useNavigate } from 'react-router-dom';

import { Header } from "./Header";
import { Footer } from "./Footer";
import { SignInHeader } from "./SignInHeader";
import { SignInFooter } from "./SignInFooter";
import "./styles.css";

import { UserHomePage } from './containers/userhome/UserHome';

import {  Header as HeaderPage} from './containers';

import moment, { normalizeUnits } from 'moment';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

 
import { Doughnut } from "react-chartjs-2";

import { MDBDataTable,MDBTable,MDBTableHead,MDBTableBody } from 'mdbreact';


import { UserHome } from "./UserHome";

import ErrorPage from "./error-page";

import Root from "./routes/root";

//import { UserProfileUpdateForm } from "./ui-components/UserProfileUpdateForm";

import {
  UserProfileUpdateForm , SelfUserProfileUpdateForm, UserProfileCreateForm
 } from './ui-components';

 


 import { CreateNote,  NoteUICollection, UpdateNote } from './ui-components'


// import query definition
import { listPosts, getPost , ListUserProfiles, getUserProfile} from "./graphql/queries";
import * as queries from './graphql/queries';
import * as mutations from './graphql/mutations';
import * as subscriptions from './graphql/subscriptions';

import { TaskContainer, TaskItem } from "./task";




import {
    DataGrid,
    GridCallbackDetails,
    GridEventListener,
    GridRowParams,
    GridSelectionModel,
  } from "@mui/x-data-grid";


import Radio from "@mui/material/Radio";

import "@aws-amplify/ui-react/styles.css";

import './App.css';

import { NavBar } from './ui-components'
import { Post, UserProfile } from "./models";

import { Selection, Form, Button, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';


import { ID, valuesEqual } from "@aws-amplify/datastore/lib-esm/util";
import { getValueFromValueOptions } from "@mui/x-data-grid/components/panel/filterPanel/filterPanelUtils";

import Table from 'react-bootstrap/Table';
import { isHomeOrEndKeys } from "@mui/x-data-grid/utils/keyboardUtils";
import { appBarClasses } from "@mui/material";

import { MDBContainer } from "mdbreact";
import { Pie } from "react-chartjs-2";
import { faker } from '@faker-js/faker';



import ChatBot from './ChatBot';


import { Footer as PageFooter, Header as PageHeader} from './containers';

import { Navbar } from './ui-components'
 
import {Chart as ChartG} from 'react-google-charts';


import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';

import { Line,Bar } from 'react-chartjs-2';

import { ModelSortPredicateCreator , SortDirection, Predicates} from "@aws-amplify/datastore";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  ArcElement,
  Legend
);





 

const ChartComponent = () => {
  const data = {
    labels: ['FAT', 'BMI', 'BMR', 'Visceral Fat', 'Bone Mass', 'Muscle Mass', 'Water', 'Protein', 'Obesity'],
    datasets: [
      {
        label: 'Low',
        data: [10, 18.5, 1425, 3, 2.5, 33.1, 45, 16, 0],
        backgroundColor: 'blue',
      },
      {
        label: 'Healthy',
        data: [20, 24.9, 1575, 13, 3.5, 39.1, 60, 20, 0],
        backgroundColor: 'green',
      },
      {
        label: 'High',
        data: [25, 29.9, 1700, 17, 4.0, 45, 70, 25, 0],
        backgroundColor: 'yellow',
      },
      {
        label: 'Obesity',
        data: [35, 35, 0, 20, 0, 0, 0, 0, 30],
        backgroundColor: 'red',
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Body Composition Ranges',
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  return <Bar data={data} options={options} />;
};



export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' 
    },
    title: {
      display: true,
      text: 'Chart.js Line Chart',
    },
  },
};


 


// npm install react-bootstrap bootstrap

const rows = [
    { id: 1, title: "ISHD Demo grid 1", content: "ISHD Demo grid content 1" },
    { id: 2, title: "ISHD Demo grid 1", content: "ISHD Demo grid content 2" }
  ];

  let radioChecked = [rows[0].id];

  const columns = [
    {
      field: "radiobutton",
      headerName: "",
      width: 100,
      sortable: false,
      renderCell: (params) => (
        <Radio checked={radioChecked[0] === params.id} value={params.id} />
      )
    },
    {
      field: "id",
      headerName: "ID"
    },
    {
      field: "name",
      headerName: "Name",
      width: 450
    },

    {
      field: "weight",
      headerName: "Weight",
      width: 150
    },

    {
      field: "bmi",
      headerName: "BMI",
      width: 150
    },
    {
      field: "fat",
      headerName: "FAT",
      width: 150
    }
  ];

  /*

async function description(){
    try {
       const allPostData = await API.graphql({ query: queries.listPosts });
        console.log('Posts Query successfully!', JSON.stringify(allPostData, 2));
      } catch (error) {
        console.log('Error retrieving posts', error);
      }
}

*/

const router = createBrowserRouter([
  {
    path: "/",
    element: <div>  Hello ISHS !</div>,
    element: <Root />,
    errorElement: <ErrorPage />,
  },
]);

const userProfileEmail = createContext();

// create initial state
const initialState = {
  email: "",
  name: "",
  dob: "",
  gender: "",
  height: "",
  company: "",
};


Amplify.configure(config);








function App({ signOut, user }) {

  const [posts, setPosts  ] = useState([]);
  const [postsGraph, setPostsGraph  ] = useState([]);

  const [postsFitness, setPostsFitness  ] = useState([]);

  const [userProfile, setProfile  ] = useState([]);

  const [fetchUserProfiles, setUserProfile  ] = useState([]);
  
  const [userGroupDetails, setUserGroupDetails  ] = useState([]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  //const [isAdmin, setisAdmin  ] = useState([]);





    const [selectionModel, setSelectionModel] = React.useState(radioChecked);
    radioChecked = selectionModel;
  
    const selectedRow = rows.filter((item) => {
      return item.id === selectionModel[0];
    });


    

   // const [chartData, setChartData ] = useState([]);
   const timestamp = new Date().getTime();

  useEffect(() => {
    fetchPosts();
    fetchProfile();
    fetchUserProfile();
    fetchUserGroupDetails();
  }, []);



  const [showChatBot, setShowChatBot] = useState(false);

    const handleOpenChatBot = () => {
        setShowChatBot(true);
    };

    const handleCloseChatBot = () => {
        setShowChatBot(false);
    };


    



  async function fetchUserGroupDetails() {
    try {
      
      const userEmail =  user.attributes.email;

     


//     const userProfiles = await API.graphql(graphqlOperation( queries.listUserProfiles, {filter: {email: {eq: userEmail}}}));


      //const models = await DataStore.query(UserGroup);
      //console.log(models);

    // const userGroupDetails = await API.graphql(graphqlOperation( queries.listUserGroups));

     const userGroupDetails = await API.graphql(graphqlOperation( queries.listUserGroups, {filter: {email: {eq: userEmail}}}));

     const userGroupDetailsData = userGroupDetails.data.listUserGroups.items;

     const userGroupName = JSON.parse( JSON.stringify ( userGroupDetailsData.map(({groupname}) => groupname)) );
     const userGroupEmail = JSON.parse( JSON.stringify ( userGroupDetailsData.map(({email}) => email)) );



   //  const isAdmin = (userEmail===userGroupEmail)?true:false;


      
     //const userGroup =   user.attributes.sub;

     //const userProfilesDataEmail = JSON.parse( JSON.stringify ( userProfilesData.map(({email}) => email)));

     //const userProfilesDataName = JSON.parse( JSON.stringify ( userProfilesData.map(({company}) => company)));
     
     //console.log("  user Profile Emails = > "+ JSON.stringify(userProfilesDataEmail) );
     
     //console.log("  user Profile Name = > "+ JSON.stringify(userProfilesDataName) );

    // console.log("  user Group Name = > "+ JSON.stringify(userGroupDetailsData) );

     setUserGroupDetails(userGroupDetailsData);

    // setisAdmin(isAdmin);

    } catch (err) {
     // console.log({ err });
    }

    
  }



  //console.log("  outside isAdmin = > "+ isAdmin );



  async function fetchUserProfile() {
    try {
      
      const userEmail =  user.attributes.email;

      const userGroupDetails = await API.graphql(graphqlOperation( queries.listUserGroups, {filter: {email: {eq: userEmail}}}));

      const userGroupNameData = userGroupDetails.data.listUserGroups.items;

   //   console.log("  user userGroupNameData  = > "+ JSON.stringify(userGroupNameData) );

      const userGroupName = JSON.parse( JSON.stringify ( userGroupNameData.map(({groupname}) => groupname)) );
   

     // console.log("  user Group Name = > "+ userGroupName );



    const fetchUserProfiles = await API.graphql(graphqlOperation( queries.listUserProfiles, {filter: {email: {eq: userEmail}}}));

 //    const fetchUserProfiles = await API.graphql(graphqlOperation( queries.listUserProfiles));

 //const fetchUserProfiles = await API.graphql(graphqlOperation( queries.listUserProfiles, {filter: {company: {eq: ""+userGroupName}}}));

 //const fetchUserProfiles = await API.graphql(graphqlOperation( queries.listUserProfiles, {filter: {company: {eq: userGroupName}}}));


     const fetchUserProfilesData = fetchUserProfiles.data.listUserProfiles.items;
      
     //const userGroup =   user.attributes.sub;

     //const userProfilesDataEmail = JSON.parse( JSON.stringify ( userProfilesData.map(({email}) => email)));

     //const userProfilesDataName = JSON.parse( JSON.stringify ( userProfilesData.map(({company}) => company)));
     
     //console.log("  user Profile Emails = > "+ JSON.stringify(userProfilesDataEmail) );
     
     //console.log("  user Profile Name = > "+ JSON.stringify(userProfilesDataName) );



     setUserProfile(fetchUserProfilesData);


    } catch (err) {
     // console.log({ err });
    }

    
  }



  async function fetchProfile() {
    try {
      
      const userEmail =  user.attributes.email;

      const userGroupDetails = await API.graphql(graphqlOperation( queries.listUserGroups, {filter: {email: {eq: userEmail}}}));

      const userGroupNameData = userGroupDetails.data.listUserGroups.items;

   //   console.log("  user userGroupNameData  = > "+ JSON.stringify(userGroupNameData) );

      const userGroupName = JSON.parse( JSON.stringify ( userGroupNameData.map(({groupname}) => groupname)) );
   

     // console.log("  user Group Name = > "+ userGroupName );



//     const userProfiles = await API.graphql(graphqlOperation( queries.listUserProfiles, {filter: {email: {eq: userEmail}}}));

 //    const userProfiles = await API.graphql(graphqlOperation( queries.listUserProfiles));

 const userProfiles = await API.graphql(graphqlOperation( queries.listUserProfiles, {filter: {company: {eq: ""+userGroupName}}}));

 //const userProfiles = await API.graphql(graphqlOperation( queries.listUserProfiles, {filter: {company: {eq: userGroupName}}}));


     const userProfilesData = userProfiles.data.listUserProfiles.items;
      
     //const userGroup =   user.attributes.sub;

     //const userProfilesDataEmail = JSON.parse( JSON.stringify ( userProfilesData.map(({email}) => email)));

     //const userProfilesDataName = JSON.parse( JSON.stringify ( userProfilesData.map(({company}) => company)));
     
     //console.log("  user Profile Emails = > "+ JSON.stringify(userProfilesDataEmail) );
     
     //console.log("  user Profile Name = > "+ JSON.stringify(userProfilesDataName) );



     setProfile(userProfilesData);


    } catch (err) {
     // console.log({ err });
    }

    
  }


  async function fetchPosts() {
    try {
      
      const userEmail =  user.attributes.email;

        const queryParams = {
            sort: {
              direction: 'asc',
              field: 'createdAt'
            }
          };
          
          var today = new Date();

          const curTime = today.getFullYear()+"-"+today.getMonth+"-"+today.getDay+" "+today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();


    // const postData = await API.graphql({ query: queries.listPosts});


       //const postData = await API.graphql(graphqlOperation( listPosts) );

     // const postData = await API.graphql(graphqlOperation( listPosts, {filter: {email: {eq: "contact@ivssofttech.com"}}} )) ;
       
   
     //console.log("  user.attributes.email userEmail = > "+userEmail);


    // const postData = await API.graphql(graphqlOperation( listPosts, {filter: {email: {eq: userEmail}}, createdAt: {gt: curTime}} )) ; 

     // const data =postData.data.listPosts.items;
   

     const postDataFitness = await DataStore.query(Post, (c) => c.status.eq("ACTIVE"), 
     
      {sort: (s) => s.createdAt(SortDirection.DESCENDING)} );


     const postData = await DataStore.query(Post, (c) => c.email.eq(userEmail), 
     
     {sort: (s) => s.createdAt(SortDirection.DESCENDING)} 
 
   //{sort: (s) => s.createdAt(SortDirection.ASCENDING)} 
 
 );

 


 const postDataGraph = await DataStore.query(Post, (c) => c.email.eq(userEmail), 
 
  //   {sort: (s) => s.createdAt(SortDirection.DESCENDING)} 
 
   {sort: (s) => s.createdAt(SortDirection.ASCENDING)} 
 
 );


     const data =postData;
     const dataGraph =postDataGraph;

     const dataFitness =postDataFitness;

     // const postData = await DataStore.query( Post );
      
     

    /* data = data.sort((a, b) => {
        if (a.createdAt < b.createdAt) {
          return -1;
        }
      });

*/
      setPosts(data);
      setPostsGraph(dataGraph);
      setPostsFitness(dataFitness);
  
    } catch (err) {
     // console.log({ err });
    }

    
  }


  let postProfileFitnessData = [];

  const fitStatus = [];

  let obesity = 0;
  let overweight =0;
  let normalw=0;
  let underweight=0;


  userProfile.map(profile => {
    postProfileFitnessData.push({company: profile.company, email: profile.email,height: profile.height } );
   });
  
  
 // console.log("postProfileFitnessData length kola  8 sep "+postProfileFitnessData.length);
  
  //postProfileFitnessData.forEach((day) => { console.log("postDataAdmin kola  8 sep "+day.email);});
  
const uniquepostsFitness = [];

/*
postsFitness.map(day => {

  postProfileFitnessData.map(profile => {{

  if ( profile.email === day.email) { 

    if (uniquepostsFitness.indexOf(day) === -1) {
      console.log(" uniquepostsFitness => day kola :"+day.email);   
     uniquepostsFitness.push(day)
    }

  }

}})

});

console.log("uniquepostsFitness length kola  8 sep "+uniquepostsFitness.length);

*/

let unique1 = postsFitness.filter((o) => postProfileFitnessData.indexOf(o) === -1);

const totalMembersInGym = postProfileFitnessData.filter((o) => postsFitness.indexOf(o) === -1);

//console.log(" unique1 kola  8 sep = "+ unique1.length);

// console.log(" total Members In Gym kola  8 sep = "+ totalMembersInGym.length);

let cnt =0;

let pst =0;


let postsFitnessnw =[];

postsFitnessnw = postsFitness.filter((item, index) => postsFitness.indexOf(item) === index);

/*
const uniquePosts = [];

postsFitnessnw.map(day => {
    if (postsFitnessnw.indexOf(day.email) === -1) {
     // console.log(" uniqueCalDates => day kola :"+day);   
     uniquePosts.push(day)
    }
});

console.log(" uniquePosts "+ uniquePosts.length);

*/


function isEmailInArray(needle, haystack) {
  for (var i = 0; i < haystack.length; i++) {
    if (needle.email === haystack[i].email) {
      return true;
    }
  }
  return false;
}

postsFitnessnw.sort(function(a, b){
  return a - b 
});


const uniqueEmailPosts = [];
  for (var i = 0; i < postsFitnessnw.length; i++) {
    if (!isEmailInArray(postsFitnessnw[i], uniqueEmailPosts)) {
      uniqueEmailPosts.push(postsFitnessnw[i]);
    }
  }
  
   //console.log(" uniqueEmailPosts = "+uniqueEmailPosts.length);



  uniqueEmailPosts.forEach((post) => { 

  pst++;

postProfileFitnessData.forEach((profile) => { 

  cnt++;
  
 // console.log(" cnt kola  8 sep "+cnt);

    if (post.email  === profile.email ) {   
     
  //    console.log(" fit.email kola  8 sep "+profile.email);
        
      if( ((JSON.parse("["+post.content+"]")
         .map(item => 
         item.nameValuePairs.Weight )/profile.height/profile.height)*10000) >= 30){
          
          obesity=obesity+1;

          //  console.log(" createdAt kola  8 sep "+post.createdAt);
          //console.log(" fit.email kola  8 sep "+profile.email);
          //console.log(" Weight kola  8 sep "+ JSON.parse("["+post.content+"]").map(item => item.nameValuePairs.Weight ));



   
         }else if( ((JSON.parse("["+post.content+"]")
         .map(item => 
         item.nameValuePairs.Weight
         )/profile.height/profile.height)*10000) >= 25 &&  ((JSON.parse("["+post.content+"]")
         .map(item => 
         item.nameValuePairs.Weight
         )/profile.height/profile.height)*10000) <= 29.9){
   
          overweight =overweight+1;

          //  console.log(" createdAt kola  8 sep "+post.createdAt);
          //console.log(" fit.email kola  8 sep "+profile.email);
          //console.log(" Weight kola  8 sep "+ JSON.parse("["+post.content+"]").map(item => item.nameValuePairs.Weight ));


         }else if( ((JSON.parse("["+post.content+"]")
         .map(item => 
         item.nameValuePairs.Weight
         )/profile.height/profile.height)*10000) > 18.5 &&  ((JSON.parse("["+post.content+"]")
         .map(item => 
         item.nameValuePairs.Weight
         )/profile.height/profile.height)*10000) <= 24.9){
   
          normalw = normalw+1;

        //  console.log(" createdAt kola  8 sep "+post.createdAt);
          //console.log(" fit.email kola  8 sep "+profile.email);
          //console.log(" Weight kola  8 sep "+ JSON.parse("["+post.content+"]").map(item => item.nameValuePairs.Weight ));

         }else if(  ((JSON.parse("["+post.content+"]")
         .map(item => 
         item.nameValuePairs.Weight
         )/profile.height/profile.height)*10000) <= 18.5){
   
          underweight =underweight+1;
         }
         

      }
     })

    });


    //console.log(" pst outside kola  8 sep "+pst);

    //console.log(" cnt outside kola  8 sep "+cnt);


    //console.log("obesity kola  8 sep "+obesity);
    //console.log("overweight kola  8 sep "+overweight);
    //console.log("normal kola  8 sep "+normalw);
    //console.log("underweight kola  8 sep "+underweight);



    const fittotal = obesity+ overweight+ normalw+ underweight;

   //console.log(" fittotal= "+ fittotal);

    fitStatus.push((obesity/fittotal)*100);
    fitStatus.push((overweight/fittotal)*100);
    fitStatus.push((normalw/fittotal)*100);
    fitStatus.push((underweight/fittotal)*100);

    //console.log("fitStatus[0] obesity kola  8 sep "+fitStatus[0]);
    //console.log("fitStatus[1] overweight kola  8 sep "+fitStatus[1]);
    //console.log("fitStatus[2] normalw kola  8 sep "+fitStatus[2]);
    //console.log("fitStatus[3] underweight kola  8 sep "+fitStatus[3]);


    //let fittotal = fitStatus[0]+fitStatus[1]+fitStatus[2]+fitStatus[3];



//    console.log(" fittotal admin  8 sep "+fittotal);
  
  //  console.log(" fittotal obesity % admin  8 sep "+((fitStatus[0]/fittotal)*100));
    //console.log(" fittotal overweight % admin  8 sep "+((fitStatus[1]/fittotal)*100));
    //console.log(" fittotal normalw % admin  8 sep "+((fitStatus[2]/fittotal)*100));
    //console.log(" fittotal underweight % admin  8 sep "+((fitStatus[3]/fittotal)*100));




  const nextCalDays = [

    '2023-07-01',
    '2023-07-05',
    '2023-07-06',
    '2023-07-07',
    '2023-07-18',
    '2023-06-17',
    '2023-06-28',
    '2023-06-29'
    ];

      
  const labelsCal =  JSON.parse( JSON.stringify ( posts.map(({createdAt}) => moment(createdAt).format('YYYY-MM-DD'))) );

  const lblCallength = labelsCal.length;

  /*
const labels = JSON.parse( JSON.stringify ( posts.map(({createdAt}) => new Date(createdAt).toLocaleString(
    "en-US",
      {
        month: "short",
        day: "2-digit",
        year: "numeric",
        hour: '2-digit',
         minute: '2-digit',
      }
  ))) );
*/



const labels = JSON.parse( JSON.stringify ( postsGraph.map(({createdAt}) => new Date(createdAt).toLocaleString(
  "en-US",
    {
      month: "short",
      day: "2-digit",
      year: "numeric",
      hour: '2-digit',
       minute: '2-digit',
    }
))) );

  labels.sort(function(a, b){
    return a - b 
});


  const resultslabelsCalDate = labelsCal.map(date => new Date(date));

  const mdates = resultslabelsCalDate;

  mdates.sort(function(a, b){
    return a - b 
});


const mdatesAftSrt = mdates.map(mdate => moment(new Date(mdate)).format('YYYY-MM-DD'));

//console.log(" dates mdatesAftSrt kola "+mdatesAftSrt);

  




const data = {
  labels,
  datasets: [
    {
      label: 'Weight(kg)',
      title: { text: "DAILY WEIGHT TREND", display:true },
      data: (postsGraph.map((post) => JSON.parse("["+post.content+"]")
      .map(item => 
        item.nameValuePairs.Weight
      ))).toLocaleString('') .split(',')

      
      
      ,
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',

    },

    {
        label: 'BMI',
        title: { text: "DAILY WEIGHT TREND", display:true },
        data: (postsGraph.map((post) => JSON.parse("["+post.content+"]")
        .map(item => 
          item.nameValuePairs.BMI
        ))).toLocaleString('') .split(','),
        borderColor: 'rgb(120, 120, 242)',
        backgroundColor: 'rgba(120, 120, 242, 0.5)',
      },

      {
        label: 'FAT',
        title: { text: "DAILY WEIGHT TREND", display:true },
        data:  (postsGraph.map((post) => JSON.parse("["+post.content+"]")
        .map(item => 
          item.nameValuePairs.FAT
        ))).toLocaleString('') .split(','),
        borderColor: 'rgb(255, 132, 99)',
        backgroundColor: 'rgba(255, 132, 99, 0.5)',
      },
  ],
}
 


 

const dataGrid = JSON.parse( JSON.stringify ( posts.map(({ "name":name}) => name)) );     


const dataGridValue  =JSON.parse("["+posts.map(({ content}) => content)+"]");
 

// console.log(" nameValuePairsData => "+ nameValuePairsData);

// console.log(" dataGridValue => "+ dataGridValue);   



//const mytable = this.getTable();

const userGroupEmail = JSON.parse( JSON.stringify ( userGroupDetails.map(({email}) => email)) );

const userProfileEmail = fetchUserProfiles.map(({email}) => email);

//console.log( " user profile "+ userProfileEmail.length);

const isAdmin = (user.attributes.email === ""+userGroupEmail ? "true": "false") ;

const isProfile = ( userProfileEmail.length >0 ? "true": "false") ;

const [showForm, setShowForm] = useState(true) ;
const [visible, setVisible] = useState(false);


const handleSuccess = (fields) => {
  alertHandler({
      type: 'success',
      message: 'Successfully submitted new item!'
  });
  setVisible(false);
};

const handleError = (fields, msg) => {
  alertHandler({
      type: 'error',
      message: `There was an issue submitting your new item: ${msg}`
  });
  setVisible(false);
};


//const homePage = useNavigate();
//homePage('/');

//const adminPage = useNavigate();
//adminPage('/ishs');

/*
 <h3> Hello  {user.attributes.email} !</h3>
  {
    fetchUserProfiles.map((profile) => 
    <>
     {
    
    profile.name 
  
}   
</>

) }


{
userProfile.map((profile) => 
<>
 {

profile.name

}   
</>

) }

*/



let currentMonthCalDateObject = [];
let e =0;

/* current month data only

mdatesAftSrt.forEach((day) => {
  if(currentMonth === moment(new Date(day)).format('MMMM')){
   // console.log(" current month dates"+moment(new Date(day)).format('YYYY-MM-DD') );
    currentMonthCalDateObject.push(moment(new Date(day)).format('YYYY-MM-DD'));
    e++;
  }
});

*/

mdatesAftSrt.forEach((day) => {
    currentMonthCalDateObject.push(moment(new Date(day)).format('YYYY-MM-DD'));
});

const uniqueCalDates = [];

currentMonthCalDateObject.map(day => {
    if (uniqueCalDates.indexOf(day) === -1) {
     // console.log(" uniqueCalDates => day kola :"+day);   
      uniqueCalDates.push(day)
    }
});

 
//uniqueCalDates.push('2023-08-08');

//console.log("uniqueCalDates");
//console.log(uniqueCalDates);

//console.log("nextCalDays");
//console.log(nextCalDays);


let postDataM = [];

//postDataM.push({createdat:'2023-07-09 14:15:39', name: 'Alok', weight: '96', bmi:'28', fat:'30'} );


posts.map(post => {
  
   // console.log(" uniqueCalDates => day kola :"+day);   
   postDataM.push({createdat: post.createdAt, name: post.name, email: post.email,
    
    weight: JSON.parse("["+post.content+"]")
    .map(item => 
    item.nameValuePairs.Weight
    ), 
    
    bmi:JSON.parse("["+post.content+"]")
   .map(item => 
   item.nameValuePairs.BMI
   ), 
   fat:JSON.parse("["+post.content+"]")
   .map(item => 
   item.nameValuePairs.FAT
   ), 
   Muscle:JSON.parse("["+post.content+"]")
   .map(item => 
   item.nameValuePairs.Muscle
   ), 
   BMR:JSON.parse("["+post.content+"]")
   .map(item => 
   item.nameValuePairs.BMR
   ), 
   Bone:JSON.parse("["+post.content+"]")
   .map(item => 
   item.nameValuePairs.Bone
   ), 
   Water:JSON.parse("["+post.content+"]")
   .map(item => 
   item.nameValuePairs.Water
   ), 
   Protein:JSON.parse("["+post.content+"]")
   .map(item => 
   item.nameValuePairs.Protein
   ) 
  
  
  } );
  }
);



//console.log("postDataM kola  8 sep "+JSON.stringify(postDataM));


let postFitnessDataAdmin = [];
let fitResult = [];
let lastTimeAccess = [];

let postDataAdmin = [];






function decycle(obj, stack = []) {
  if (!obj || typeof obj !== 'object')
      return obj;
  
  if (stack.includes(obj))
      return null;

  let s = stack.concat([obj]);

  return Array.isArray(obj)
      ? obj.map(x => decycle(x, s))
      : Object.fromEntries(
          Object.entries(obj)
              .map(([k, v]) => [k, decycle(v, s)]));
}


function calculateAge(dateOfBirth) {
  const dob = new Date(dateOfBirth);
  const now = new Date();
  
  let age = now.getFullYear() - dob.getFullYear();
  const monthDiff = now.getMonth() - dob.getMonth();
  
  if (monthDiff < 0 || (monthDiff === 0 && now.getDate() < dob.getDate())) {
    age--;
  }
  
  return age;
}

// Usage example
const dob = "1980-11-01";
const age = calculateAge(dob);
console.log("Age:", age);


//postDataM.push({createdat:'2023-07-09 14:15:39', name: 'Alok', weight: '96', bmi:'28', fat:'30'} );

//("<a href=/userhome?email="+profile.email+">"+profile.name+"</a>")
//  console.log(" uniqueCalDates => day kola :"+day);  

/*
userProfile.map(profile => {
  
   
   postDataAdmin.push({company: profile.company, name: (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div
        className="uil-trash-alt"
        style={{
          cursor: "pointer",
          color: "black",
          fontSize: ".7em",
          padding: ".5rem",
          borderRadius: ".3rem",
          background: "#fbac62",
        }}
        onClick={() => window.open("/userhome?email="+profile.email, '_self', 'noopener,noreferrer')}
      >
        {profile.name}
      </div>
    </div>
  ), dob: profile.dob,height: profile.height,
    
    dietstatus: profile.dietstatus,ptstatus: profile.ptstatus,selfishsstatus: profile.selfishsstatus

  } );
  }
);
*/
fitResult = 'No record';
lastTimeAccess = '';

userProfile.map(profile => {
  
  // console.log(" uniqueCalDates => day kola :"+day);   
  postFitnessDataAdmin.push({company: profile.company, name: (
   <div style={{ display: "flex", justifyContent: "space-between" }}>
     <div
       className="uil-trash-alt"
       style={{
         cursor: "pointer",
         color: "black",
         fontSize: ".7em",
         padding: ".5rem",
         borderRadius: ".3rem",
         background: "#fbac62",
       }}
       onClick={() => window.open("/userhome?email="+profile.email, '_self', 'noopener,noreferrer')}
     >
       {profile.name}
     </div>
   </div>
 ), emailno:profile.email, 
 



fit: ''+
(
  
  uniqueEmailPosts.map(post => {
 //console.log(" kola BMI = "+(((JSON.parse("["+post.content+"]").map(item =>  item.nameValuePairs.Weight))/profile.height/profile.height)*10000));

   // console.log(" before compare KOLA Fitness BMI => "+ post.email+" profile.email > "+profile.email); 

  

     
  // if (post.email === profile.email) {  
  if (profile.email === post.email ) {
     
    lastTimeAccess = post.createdAt;

      if( ((JSON.parse("["+post.content+"]")
      .map(item => 
      item.nameValuePairs.Weight
      )/profile.height/profile.height)*10000) >= 30){
        
        fitResult = 'Obesity 🚴‍♀️🏃‍♂️🧘‍♂️🥗';

      }else if( ((JSON.parse("["+post.content+"]")
      .map(item => 
      item.nameValuePairs.Weight
      )/profile.height/profile.height)*10000) >= 25 &&  ((JSON.parse("["+post.content+"]")
      .map(item => 
      item.nameValuePairs.Weight
      )/profile.height/profile.height)*10000) <= 29.9){

        fitResult = 'Overweight 🏃🧘‍♂️🥗';
      }else if( ((JSON.parse("["+post.content+"]")
      .map(item => 
      item.nameValuePairs.Weight
      )/profile.height/profile.height)*10000) > 18.5 &&  ((JSON.parse("["+post.content+"]")
      .map(item => 
      item.nameValuePairs.Weight
      )/profile.height/profile.height)*10000) <= 24.9){

        fitResult = 'Good 😊 🧘‍♂️';
      }else if(  ((JSON.parse("["+post.content+"]")
      .map(item => 
      item.nameValuePairs.Weight
      )/profile.height/profile.height)*10000) <= 18.5){

        fitResult = 'Underweight 🧘‍♂️🥗';
      }
      
     
  } 

  })
  )
 ,

 fitness : fitResult
  
 ,lta:lastTimeAccess,dob: calculateAge(profile.dob),height: profile.height,
   
   dietstatus: profile.dietstatus,ptstatus: profile.ptstatus,selfishsstatus: profile.selfishsstatus

 } );
 }
);




//console.log("postFitnessDataAdmin kola  8 sep "+JSON.stringify(postFitnessDataAdmin));



const dataM = {
  columns: [
    {
      label: 'Created At',
      field: 'createdat',
      sort: 'asc',
      width: 150
    },
    {
      label: 'Name',
      field: 'name',
      sort: 'asc',
      width: 270
    },
    {
      label: 'Weight(Kg)',
      field: 'weight',
      sort: 'asc',
      width: 200
    },
    {
      label: 'BMI',
      field: 'bmi',
      sort: 'asc',
      width: 200
    },
    {
      label: 'FAT(%)',
      field: 'fat',
      sort: 'asc',
      width: 200
    },
    {
      label: 'Muscle(%)',
      field: 'Muscle',
      sort: 'asc',
      width: 200
    },
    {
      label: 'BMR(Kcal/day)',
      field: 'BMR',
      sort: 'asc',
      width: 200
    },
    {
      label: 'Bone(Kg)',
      field: 'Bone',
      sort: 'asc',
      width: 200
    },
    {
      label: 'Water(%)',
      field: 'Water',
      sort: 'asc',
      width: 200
    },
    {
      label: 'Protein(%)',
      field: 'Protein',
      sort: 'asc',
      width: 200
    }

  ],
  rows: postDataM

  }; 

   


    const dataAdmin = {
      columns: [
   
   /*     {
          label: 'Fitness Center',
          field: 'company',
          sort: 'asc',
          width: 150
        },
     */
        {
          label: 'Name',
          field: 'name',
          sort: 'asc',
          width: 270
        },
        {
          label: 'DoB',
          field: 'dob',
          sort: 'asc',
          width: 200
        },
        {
          label: 'Height',
          field: 'height',
          sort: 'asc',
          width: 200
        },
        {
          label: 'Diet Plan',
          field: 'dietstatus',
          sort: 'asc',
          width: 200
        },
        {
          label: 'PT Session',
          field: 'ptstatus',
          sort: 'asc',
          width: 200
        }
       
       /* ,
        {
          label: 'Self ISHS',
          field: 'selfishsstatus',
          sort: 'asc',
          width: 200
        }
  */
  
      ],
      rows: postDataAdmin
  
      }; 


      const dataFtinessAdmin = {
        columns: [
      /*
          {
            label: 'Fitness Center',
            field: 'company',
            sort: 'asc',
            width: 150
          },
      */

          {
            label: 'Name',
            field: 'name',
            sort: 'asc',
            width: 270
          },
          /*
          {
            label: 'Email',
            field: 'email',
            sort: 'asc',
            width: 200
          },
         */

          
          {
            label: 'Fitness Status',
            field: 'fitness',
            sort: 'asc',
            width: 200
          },
          {
            label: 'Last Time Access',
            field: 'lta',
            sort: 'asc',
            width: 200
          },
          {
            label: 'Age',
            field: 'dob',
            sort: 'asc',
            width: 200
          },
          {
            label: 'Height',
            field: 'height',
            sort: 'asc',
            width: 200
          },
          {
            label: 'Diet Plan',
            field: 'dietstatus',
            sort: 'asc',
            width: 200
          },
          {
            label: 'PT Session',
            field: 'ptstatus',
            sort: 'asc',
            width: 200
          }
       
       /*   ,
          {
            label: 'Self ISHS',
            field: 'selfishsstatus',
            sort: 'asc',
            width: 200
          }
    */
    
        ],
        rows: postFitnessDataAdmin
    
        }; 



      const myEmail =  user.attributes.email;



     
    const TableComponent = ({ posts }) => {
      // Function to determine row color based on BMI value
      const getRowColorClass = (bmi) => {
        if (bmi < 18.5) return 'low-bmi';
        if (bmi >= 18.5 && bmi <= 24.9) return 'healthy-bmi';
        if (bmi >= 25 && bmi <= 29.9) return 'high-bmi';
        return 'obesity-bmi';
      };
    
      const postDataM = posts.map(post => {
        const content = JSON.parse(`[${post.content}]`)[0].nameValuePairs;
    
        return {
          createdat: post.createdAt,
          name: post.name,
          weight: content.Weight,
          bmi: content.BMI,
          fat: content.FAT,
          BMR: content.BMR,
          Visceral: content.Visceral,
          Muscle: content.Muscle,
          Bone: content.Bone,
          Water: content.Water,
          Protein: content.Protein,
          bmiClass: getRowColorClass(content.BMI) // Add the bmiClass property
        };
      });
    
      const dataM = {
        columns: [
          { label: 'Created At', field: 'createdat', sort: 'asc', width: 150 },
          { label: 'Name', field: 'name', sort: 'asc', width: 270 },
          { label: 'Weight(Kg)', field: 'weight', sort: 'asc', width: 200 },
          { label: 'BMI', field: 'bmi', sort: 'asc', width: 200 },
          { label: 'FAT(%)', field: 'fat', sort: 'asc', width: 200 },
          { label: 'BMR(Kcal/day)', field: 'BMR', sort: 'asc', width: 200 },
          { label: 'Visceral FAT(%)', field: 'Visceral', sort: 'asc', width: 200 },
          { label: 'Muscle(%)', field: 'Muscle', sort: 'asc', width: 200 },
          { label: 'Bone(Kg)', field: 'Bone', sort: 'asc', width: 200 },
          { label: 'Water(%)', field: 'Water', sort: 'asc', width: 200 },
          { label: 'Protein(%)', field: 'Protein', sort: 'asc', width: 200 }
        ],
        rows: postDataM
      };
    
      return (
        <MDBDataTable
          striped
          bordered
          small
          responsive
          data={dataM}
          tbodyCustomRow={(row, index) => dataM.rows[index].bmiClass} // Apply custom class to rows
        />
      );
    };


    

    let userGender =  (JSON.stringify(fetchUserProfiles.map(({gender}) => gender)) ).trim().replace(/^\["|"\]$/g, '');

//    let userGender =  (JSON.stringify(userProfile.map(({gender}) => gender)) ).trim().replace(/^\["|"\]$/g, '');

  //  let areEqual = userGender.toUpperCase() === "FEMALE";

    const getMetricClass = (value, metric) => {
      switch (metric) {
        case 'BMI':
          if (value < 18.5) return 'low';
          if (value >= 18.5 && value <= 24.9) return 'healthy';
          if (value >= 25 && value <= 29.9) return 'high';
          if (value >= 30) return 'obesity';
          break;
        case 'FAT':
          if (userGender.toLowerCase() === 'male'){

          

            if (value < 10) return 'low';
            if ((value >= 10  && value < 20)) return 'healthy';
            if ((value >= 20 && value < 25)) return 'high';
            if (value >= 25 ) return 'obesity';

          }else{
            if (value < 20) return 'low';
            if ((value >= 20  && value < 30)) return 'healthy';
            if ((value >= 30 && value < 35)) return 'high';
            if (value >= 35 ) return 'obesity';
          
          }
            
          break;
        case 'BMR':
          if (value < 1425) return 'low';
          if (value >= 1425 && value <= 1575) return 'healthy';
          if (value > 1575) return 'high';
          break;
        case 'VisceralFat':
          if (value < 4) return 'low';
          if (value >= 4 && value <= 13) return 'healthy';
          if (value >= 14 && value <= 17) return 'high';
          if (value > 17) return 'obesity';
          break;
        case 'Muscle':
          if (value < 33.1) return 'low';
          if (value >= 33.1 && value <= 39.1) return 'healthy';
          if (value > 39.1) return 'high';
          break;
        case 'Bone':
          if (value < 2.5 || value < 3.0) return 'low';
          if ((value >= 2.5 && value <= 3.5) || (value >= 3.0 && value <= 4.0)) return 'healthy';
          if (value > 3.5 || value > 4.0) return 'high';
          break;
        case 'Water':
          if (value < 45) return 'low';
          if (value >= 45 && value <= 60) return 'healthy';
          if (value > 60) return 'high';
          break;
        case 'Protein':
          if (value < 16) return 'low';
          if (value >= 16 && value <= 20) return 'healthy';
          if (value > 20) return 'high';
          break;
        default:
          return '';
      }
    };

    
 const FitnessTableComponent = ({ posts }) => {
  if (posts.length === 0) {
    return <div>No data available</div>;
  }

  const sortedPosts = posts.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  const latestPost = sortedPosts[0];
  const content = JSON.parse(`[${latestPost.content}]`)[0].nameValuePairs;

  return (
    <table className="styled-table">
      <thead>
        <tr>
          <th>Metric</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
       <tr>
          <td>Name</td>
          <td>{latestPost.name}</td>
        </tr>
        <tr>
          <td>Created At</td>
          <td>{latestPost.createdAt}</td>
        </tr>
        <tr>
          <td>Weight(Kg)</td>
          <td>{content.Weight}</td>
        </tr>
        <tr>
          <td>BMI</td>
          <td className={getMetricClass(content.BMI, 'BMI')}>{content.BMI}</td>
        </tr>
        <tr>
          <td>FAT(%)</td>
          <td className={getMetricClass(content.FAT, 'FAT')}>{content.FAT}</td>
        </tr>
        <tr>
          <td>BMR(Kcal/day)</td>
          <td className={getMetricClass(content.BMR, 'BMR')}>{content.BMR}</td>
        </tr>
        <tr>
          <td>Visceral FAT(%)</td>
          <td className={getMetricClass(content.Visceral, 'VisceralFat')}>{content.Visceral}</td>
        </tr>
        <tr>
          <td>Muscle(%)</td>
          <td className={getMetricClass(content.Muscle, 'Muscle')}>{content.Muscle}</td>
        </tr>
        <tr>
          <td>Bone(Kg)</td>
          <td className={getMetricClass(content.Bone, 'Bone')}>{content.Bone}</td>
        </tr>
        <tr>
          <td>Water(%)</td>
          <td className={getMetricClass(content.Water, 'Water')}>{content.Water}</td>
        </tr>
        <tr>
          <td>Protein(%)</td>
          <td className={getMetricClass(content.Protein, 'Protein')}>{content.Protein}</td>
        </tr>
      </tbody>
    </table>
    
  );

};


      //const stringValue = '["Diet Plan"]';
      // const modifiedString = stringValue.replace(/^\["|"\]$/g, '');

 /*
  profile: PostStatus
  breakfast: String
  lunch: String
  esnacks: String
  dinner: String
  dietaryadvice: String
  plan: Plan
  diettype: DietType

*/


      const DietPlanData = (JSON.stringify(fetchUserProfiles.map(({diet}) => diet)) ).trim().replace(/^\["|"\]$/g, '');
      const breakfast = (JSON.stringify(fetchUserProfiles.map(({breakfast}) => breakfast)) ).trim().replace(/^\["|"\]$/g, ''); 
      const lunch = (JSON.stringify(fetchUserProfiles.map(({lunch}) => lunch)) ).trim().replace(/^\["|"\]$/g, ''); 
      const esnacks = (JSON.stringify(fetchUserProfiles.map(({esnacks}) => esnacks)) ).trim().replace(/^\["|"\]$/g, ''); 
      const dinner = (JSON.stringify(fetchUserProfiles.map(({dinner}) => dinner)) ).trim().replace(/^\["|"\]$/g, ''); 
      const diettype = (JSON.stringify(fetchUserProfiles.map(({diettype}) => diettype)) ).trim().replace(/^\["|"\]$/g, '');  
      const plan = (JSON.stringify(fetchUserProfiles.map(({plan}) => plan)) ).trim().replace(/^\["|"\]$/g, '');  
      const profileStatus = (JSON.stringify(fetchUserProfiles.map(({profile}) => profile)) ).trim().replace(/^\["|"\]$/g, '');  
      const dietaryadvice = (JSON.stringify(fetchUserProfiles.map(({dietaryadvice}) => dietaryadvice)) ).trim().replace(/^\["|"\]$/g, '');  
      

      const dietString = "Morning Breakfast : cereal, Juice, Lunch : A small bowl of rice, dal or lentils, 1 small roti Evening Snacks : A small cup of fruit yogurt or a small bowl of fruit salad, Dinner : 1-2 chapatis, bowl of dal (lentils) or chole (chickpeas)";
  
      const splitDiet  = dietString.split(/\s*(Morning Breakfast|Lunch|Evening Snacks|Dinner)\s*:/).filter(Boolean)



    //  console.log(" dietplan before "+ DietPlanData);

    //  console.log(" dietplan after  "+ JSON.stringify(DietPlan));

      // Diet Plan {  DietPlanData}  
       
  return (

    

    <> 
      <NavBar width="100%" marginBottom='20px'   

        overrides={{  
          
          Button31632483: { onClick: async () =>  <>{"/"}</>  } ,

          Button33572472: { onClick: () =>  {handleShow()} } ,

          Button33572473: { onClick: async () => { alert(" submit to update") } },

          Button33572473: { onClick: async () => { signOut() } },

          ChatBotButton: { onClick: handleOpenChatBot } // New button for chatbot
      
          }}
      />

  

    <div class="App">

 
   
    <h1>ISHS Fitness Dashboard </h1>
  
    
   
 
<h3>
  {
    userGroupDetails.map((groups) => 
    <>
     {groups.groupname}   
    </>
  
    ) }
</h3>

<Modal
    show={show}
    onHide={handleClose}
    backdrop="static"
    keyboard={false}
>
    <Modal.Header closeButton>
        <Modal.Title>Update Profile</Modal.Title>
    </Modal.Header>
    <Modal.Body>
 
    <MemberProfile  
                    id = {fetchUserProfiles.map(({ "id":id}) => id)}
                    type = 'success'
                    message=  'Successfully submitted Profile Update!'
                 />

    </Modal.Body>
    <Modal.Footer>
        <Button
            onClick={handleClose}
        >
            Close
        </Button>
    </Modal.Footer>
</Modal>

    

{ 

//console.log("  in return isAdmin = > "+ isAdmin )

}



 
     
<div>
{(() => {
if ((isAdmin === "true")) {
return (
<AdminHome
userProfile= {userProfile}
dataAdmin={dataAdmin}
dataFtinessAdmin={dataFtinessAdmin}
fitStatus={fitStatus}
/>
)
}else {
return (

  (isProfile === "true")?


<>

<h3> Welcome {  fetchUserProfiles.map(({name}) => name)} !</h3>

 


<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

 {/* o section */}
 <div style={{ width: '100%', alignItems: 'center' }}> {/* Set width to 100% */}
        {/* Render the MemberReport component */}

        <HealthReport
        userProfile= {userProfile}
        posts= {posts}
        data = {data}
        height= {fetchUserProfiles.map(({height}) => height)}
        />
      </div>
       
       {/* Blank row */}
       <div style={{ height: '40px' }}></div> {/* Adjust height as needed */}


         {/* Diet Plan section */}
      <div style={{ width: '100%', alignItems: 'center' }}> {/* Set width to 100% */}
        {/* Render the MemberReport component */}


      <DietPlan
        userProfile= {userProfile}
        diet = {DietPlanData}
        breakfast = {fetchUserProfiles.map(({breakfast}) => breakfast)}
        lunch = {lunch}
        esnacks = {esnacks}
        dinner = {dinner}
        diettype = {diettype}
        plan = {plan}
        profileStatus = {profileStatus}
        dietaryadvice= {dietaryadvice}
        height= {fetchUserProfiles.map(({height}) => height)}
    
        />
      </div>







    <div style={{ height: '40px' }}></div> {/* Adjust height as needed */}

    <div style={{ width: '70%', alignItems: 'center' }}> {/* Set width to 100% */}
     <h4>Latest Body Composition Fitness Status</h4>
     </div>
<div style={{ width: '100%', alignItems: 'center' }}> {/* Set width to 100% */}
<div className="App">
      <FitnessTableComponent posts={posts} />
      <div style={{ width: '100%', alignItems: 'center' }}> {/* Set width to 100% */}
      <h5><i>Legend</i></h5>
      <table className="styled-table">
    <thead>          
         <tr>
              <th className="low">Low</th>
              <th className="healthy">Healthy</th>
              <th className="high">High</th>
              <th className="obesity">Obesity/Very High</th>
            </tr>
        </thead>    
        </table> 
     </div>
  
    </div>
  </div>






      {/* Blank row */}
      <div style={{ height: '40px' }}></div> {/* Adjust height as needed */}

      {/* First section */}
      <div style={{ width: '100%', alignItems: 'center' }}> {/* Set width to 100% */}
        {/* Render the MemberReport component */}
         <center>
         <b><h4>Fitness Progress Graph Report </h4></b>
        <MemberReport
        userProfile= {userProfile}
        posts= {posts}
        data = {data}
        />
         </center>
      </div>
       

      {/* Blank row */}
      <div style={{ height: '40px' }}></div> {/* Adjust height as needed */}
      <div className="app" style={{ width: '100%', alignItems: 'center' }}> {/* Set width to 100% */}
      <h4>Ideal Body Composition Ranges</h4>
      <table className="styled-table">
        <thead>
          <tr>
            <th>Metric</th>
            <th className="low">Low</th>
            <th className="healthy">Healthy</th>
            <th className="high">High</th>
            <th className="obesity">Obesity/Very High</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>FAT (%)</td>
            <td className="low">&lt; 10% (men), &lt; 20% (women)</td>
            <td className="healthy">10-20% (men), 20-30% (women)</td>
            <td className="high">20-25% (men), 30-35% (women)</td>
            <td className="obesity">&gt; 25% (men), &gt; 35% (women)</td>
          </tr>
          <tr>
            <td>BMI</td>
            <td className="low">&lt; 18.5</td>
            <td className="healthy">18.5 - 24.9</td>
            <td className="high">25 - 29.9</td>
            <td className="obesity">&gt; 30</td>
          </tr>
          <tr>
            <td>BMR</td>
            <td className="low">&lt; 1425 (Varies by individual)</td>
            <td className="healthy">1425 - 1575 (Varies by individual)</td>
            <td className="high">&gt; 1575 (Varies by individual)</td>
            <td className="obesity">NA</td>
          </tr>
          <tr>
            <td>Visceral Fat</td>
            <td className="low">1 - 3</td>
            <td className="healthy">4 - 13</td>
            <td className="high">14 - 17</td>
            <td className="obesity">&gt; 17</td>
          </tr>
          <tr>
            <td>Bone Mass (kg)</td>
            <td className="low">&lt; 2.5 (women), &lt; 3.0 (men)</td>
            <td className="healthy">2.5 - 3.5 (women), 3.0 - 4.0 (men)</td>
            <td className="high">&gt; 3.5 (women), &gt; 4.0 (men)</td>
            <td className="obesity">N/A</td>
          </tr>
          <tr>
            <td>Muscle Mass</td>
            <td className="low">&lt; 33.1% (Varies by individual)</td>
            <td className="healthy">33.1 - 39.1% (Varies by individual)</td>
            <td className="high">&gt; 39.1% (Varies by individual)</td>
            <td className="obesity">N/A</td>
          </tr>
          <tr>
            <td>Water (%)</td>
            <td className="low">&lt; 45%</td>
            <td className="healthy">45 - 60%</td>
            <td className="high">&gt; 60%</td>
            <td className="obesity">N/A</td>
          </tr>
          <tr>
            <td>Protein (%)</td>
            <td className="low">&lt; 16%</td>
            <td className="healthy">16 - 20%</td>
            <td className="high">&gt; 20%</td>
            <td className="obesity">N/A</td>
          </tr>
          <tr>
            <td>Obesity</td>
            <td className="low">Not applicable</td>
            <td className="healthy">Not applicable</td>
            <td className="high">Not applicable</td>
            <td className="obesity">BMI &gt; 30</td>
          </tr>
        </tbody>
      </table>
    </div>
    </div>

       {/* Blank row */}
       <div style={{ height: '40px' }}></div> {/* Adjust height as needed */}

{/* Second section */}
<div>
        <center>
          <p></p>
          <b><h4>Monthly Attendance Report</h4></b>
          <Calendar
              style={{ height: 500 }}
              //onChange={this.onChange}
            // value={this.state.date}
              tileClassName={({ date, view }) => {
                if(uniqueCalDates.find(x=>x===moment(date).format("YYYY-MM-DD"))){
                return  'highlight-present'
                }
                
              }}

              tileContent={({ date, view }) => {
                if(uniqueCalDates.find(x=>x===moment(date).format("YYYY-MM-DD"))){
                return  ' P'
                }
                
              }}


              tileDisabled={({ date }) => date.getDay() === 0}

              defaultActiveStartDate={new Date()}
              /*maxDate={new Date(2020, 1, 0)}</div>*/
              /*
              minDate={
                new Date()
              }
              */
            >
          </Calendar>
        </center>
      </div>
    

    {/* Blank row */}



{/* Blank row */}
<div style={{ height: '40px' }}></div> {/* Adjust height as needed */}

  <div style={{ width: '100%', alignItems: 'center' }}> {/* Set width to 100% */}

  <div className="App">
 <h3>Daily Fitness Report</h3>
 <TableComponent posts={posts} />
</div>

  </div>



</>

:
<MemberCreateProfile  
id = {fetchUserProfiles.map(({ "id":id}) => id)}
type = 'success'
message=  'Successfully submitted Profile Update!'
myemail= {myEmail}
/>


)

}



})()}
</div>
   
  
     

     
   



 
     
      
      
   
     
       
    
    

       
 
    

      




      


    </div>     




            <main>
                {!showChatBot && (
                    <button className="open-chatbot-button" onClick={handleOpenChatBot}>
                        Open IVS Health FAQ Bot
                    </button>
                )}
                {showChatBot && (
                    
                         <div className="chatbot-container">
                        <ChatBot onClose={handleCloseChatBot} />
                    </div>
                    
                
                )}
            </main>

    </>
  );
}





//export default withAuthenticator(UserHome);
 


export default withAuthenticator(App, {
  components: {
    Header,
    HeaderPage,
    SignIn: {
      Header: SignInHeader,
      Footer: SignInFooter
    },
    Footer
  }
});



/*

<div>
  <br></br> <br></br>
<b>
<h4> Members Summary Report </h4>
</b>


<MDBDataTable
      striped
      bordered
      small
      //hover
      responsive
      data={dataAdmin}
    />




</div>



  <MDBContainer>
      <h3 className="mt-5">Members Fitness Overview </h3>
      <Doughnut 
       

      data={state.dataDoughnut} 
      
      options={{ 
        
        responsive: true,

        plugins: {
          legend: {
            position: 'top' 
          },
          title: {
            display: true,
            text: 'Fitness Overview',
          },

          datalabels: {
            formatter: (value) => {
             // if (value < 15) return '';
              return value + '%';
            },
          },
        
        }
        
        }} 
         
        plugins={plugins} 
        
        />
    </MDBContainer>


*/

/*




  const state = {
    dataDoughnut: {
      labels: ["Obesity", "Overwieght", "Normal", "Underweight"],
      datasets: [
        {
          data: [60, 30, 9, 1],
          backgroundColor: ["#F7464A", "#FDB45C","#46BFBD", "#4D5360"],
          hoverBackgroundColor: [
            "#FF5A5E",
            "#FFC870",
            "#5AD3D1",
            "#616774"
          ]
        }
      ],
      options: {
        plugins: {
          datalabels: {
            formatter: (value) => {
             // if (value < 15) return '';
              return value + '%';
            },
          },
        },
      }
    }
  }

  const plugins = [{
    beforeDraw: function(chart) {
     var width = chart.width,
         height = chart.height,
         ctx = chart.ctx;
         ctx.restore();
         var fontSize = (height / 160).toFixed(2);
         ctx.font = fontSize + "em sans-serif";
         ctx.textBaseline = "top";
         var text = "Fit",
         textX = Math.round((width - ctx.measureText(text).width) / 2),
         textY = height / 2;
         ctx.fillText(text, textX, textY);
         ctx.save();
    } ,

    datalabels: {
      formatter: (value) => {
       // if (value < 15) return '';
        return value + '%';
      },
    },

  }]

*/

function AdminHome({userProfile,dataAdmin,dataFtinessAdmin,fitStatus}){


 // console.log("fitStatus[0] obesity admin  8 sep "+fitStatus[0]);
 // console.log("fitStatus[1] overweight admin  8 sep "+fitStatus[1]);
 // console.log("fitStatus[2] normalw admin  8 sep "+fitStatus[2]);
 // console.log("fitStatus[3] underweight admin  8 sep "+fitStatus[3]);





  const pieData = [
    ['FitnessStatus', 'Members percentage'],
    ['Underweight', fitStatus[3]],
    ['Obesity', fitStatus[0]],
    ['Overwieght', fitStatus[1]],
    ['Normal', fitStatus[2]],
  ]
  const pieOptions = {
    title: '',
    pieHole: 0.3,
    legend: { position: 'top', alignment: 'center' },
  }


  return (

    

<center>


<div className="container mt-5">
<h4> Members Fitness Overview </h4> 
        <ChartG
          width="100%"
          height="350px"
          chartType="PieChart"
          loader={<div>Loading Chart</div>}
          data={pieData}
          options={pieOptions}
          rootProps={{ 'data-testid': '3' }}
        />
</div>

<div>

<b>
<h4> Members Fitness Summary Report </h4>
</b>

<MDBDataTable
      striped
      bordered
      small
      //hover
      responsive
      data={dataFtinessAdmin}
    />




</div>


</center>


  );


}





function MemberHome({userProfile, posts,data}){

  const getClassByBmi = (bmi) => {
    if (bmi < 18.5) return 'low';
    if (bmi >= 18.5 && bmi <= 24.9) return 'healthy';
    if (bmi >= 25 && bmi <= 29.9) return 'high';
    if (bmi >= 30) return 'obesity';
    return '';
  };
  


  return (



 
   
  
  
      <div>
  
      <Table striped bordered hover responsive size="sm">
             <thead>
                 <tr>
                 <th>Created At</th>
                 <th>Name</th>
                 <th>Weight</th>
                 <th style={{ backgroundColor: '#6fa8dc' }}>BMI</th>
                 <th>FAT</th>
                 <th>Muscle</th>
                 <th>BMR</th>
                 <th>Bone</th>
                 <th>Water</th>
                 <th >Protein</th>
                 </tr>
             </thead>
             <tbody>
  
            
             
  
              {posts.map((post) =>
  
             <tr> 
               
               <td> {post.createdAt}  </td>  
   
              <td style={{ backgroundColor: '#6fa8dc' }} > {post.name} </td> 
            
              <td style={{ backgroundColor: '#6fa8dc' }}> { 
              
                JSON.parse("["+post.content+"]")
                .map(item => 
                item.nameValuePairs.Weight
                )
  
              } </td>  
  
                  <td > { 
              
                JSON.parse("["+post.content+"]")
                .map(item => 
                item.nameValuePairs.BMI
                )
  
              } </td>  

  
                  <td> { 
              
                JSON.parse("["+post.content+"]")
                .map(item => 
                item.nameValuePairs.FAT
                )
  
              } </td>     
                  <td> { 
              
              JSON.parse("["+post.content+"]")
              .map(item => 
              item.nameValuePairs.Muscle
              )

            } </td>   

      <td> { 
              
              JSON.parse("["+post.content+"]")
              .map(item => 
              item.nameValuePairs.BMR
              )

            } </td>  

        <td> { 
              
              JSON.parse("["+post.content+"]")
              .map(item => 
              item.nameValuePairs.Bone
              )

            } </td>   
        <td> { 
              
              JSON.parse("["+post.content+"]")
              .map(item => 
              item.nameValuePairs.Water
              )

            } </td>   

      <td> { 
              
              JSON.parse("["+post.content+"]")
              .map(item => 
              item.nameValuePairs.Protein
              )

            } </td>     

              </tr>
  
          )}
               
                      
             </tbody>
        </Table>
               
  
      </div>




);


}

/*

function MemberReport({userProfile, posts,data}){

  return (



<div style={{width:"120vh",height:"50vh",position:"relative", marginBottom:"1%", padding:"1%"}} > 
   
   <Line 
   
 
       
       options = {{
         responsive: true,
         maintainAspectRatio: true ,
       
         title: { text: "DAILY WEIGHT TREND", display:true },
         scales: {
             yAxes: [{
                 scaleLabel: {
                     display: true,
                     labelString: 'Weight(kg)'
                 }
             }],
             xAxes: [{
                 scaleLabel: {
                     display: true,
                     labelString: 'Dates'
                 }
             }]
         }        
    
        

       }}


     data={data} 


       />

 </div>

    );


  }
  
 */





  function DietPlan({userProfile, diet,breakfast,lunch,esnacks,dinner,diettype,plan,profileStatus,dietaryadvice,height}) {

  const colorCombination = [
    { backgroundColor: '#FFE4E1', color: '#FF5733', iconColor: '#FF5733' },
    { backgroundColor: '#FFDAB9', color: '#8B4513', iconColor: '#8B4513' },
    { backgroundColor: '#F0FFF0', color: '#228B22', iconColor: '#228B22' },
    { backgroundColor: '#E6E6FA', color: '#8A2BE2', iconColor: '#8A2BE2'  },
    { backgroundColor: '#f8ddcb', color: '#333333', iconColor: '#333333' },
    { backgroundColor: '#FFFACD', color: '#333333', iconColor: '#333333' },
    { backgroundColor: '#F0FFF0', color: '#228B22', iconColor: '#228B22' },
    { backgroundColor: '#E6E6FA', color: '#8A2BE2', iconColor: '#8A2BE2' },
    { backgroundColor: '#00FFFF', color: '#333333', iconColor: '#333333' },
    { backgroundColor: '#FF4500', color: '#333333', iconColor: '#333333' }
];

  const DietPlanComponent = () => (
      <div className="diet-plan">
          <h2 style={{ textAlign: 'center', marginBottom: '20px', color: '#FF5733' }}>Personalised Diet Plan</h2>
          <div className="meal" style={colorCombination[4]}>
              <h3>Diet Type :</h3>
              <p>{diettype}</p>
          </div>
          <div className="meal" style={colorCombination[5]}>
              <h3>Dietary Advice :</h3>
              <p>{dietaryadvice}</p>
          </div>
          <div className="meal" style={colorCombination[0]}>
              <h3><i className="fas fa-coffee"></i> Morning Breakfast:</h3>
              <p>{breakfast}</p>
          </div>
          <div className="meal" style={colorCombination[1]}>
              <h3><i className="fas fa-utensils"></i> Lunch:</h3>
              <p>{lunch}</p>
          </div>
          <div className="meal" style={colorCombination[2]}>
              <h3><i className="fas fa-apple-alt"></i> Evening Snacks:</h3>
              <p>{esnacks}</p>
          </div>
          <div className="meal" style={colorCombination[3]}>
              <h3><i className="fas fa-utensils"></i> Dinner:</h3>
              <p>{dinner}</p>
          </div>

      </div>
  );

    return (
      <div className="app">
        <DietPlanComponent />
      </div>
    );
  }

  function HealthReport({ userProfile, posts, data, height }) {
    

    const statusStyles = {
      obesity: { backgroundColor: '#FF5733', color: '#FFFFFF' },
      good: { backgroundColor: '#66BB6A', color: '#FFFFFF' },
      overweight: { backgroundColor: '#FFA726', color: '#FFFFFF' },
      underweight: { backgroundColor: '#42A5F5', color: '#FFFFFF' }
    };


    let  fitResult = [];


   const userHeight = height ;
  
   const weight = "94";


    //console.log( posts)

  //  console.log(data)


    // Assuming 'data' is your provided dataset
const weightDataset = data.datasets.find(dataset => dataset.label === 'Weight(kg)');
// Get the data array from the Weight Dataset
const weightData = weightDataset.data;
// Get the last or latest value from the weight data
const latestWeight = weightData[weightData.length - 1];
//console.log('Latest Weight:', latestWeight);

    // Assuming 'data' is your provided dataset
    const FATDataset = data.datasets.find(dataset => dataset.label === 'FAT');
    // Get the data array from the Weight Dataset
    const fatData = FATDataset.data;
    // Get the last or latest value from the weight data
    const latestFAT = fatData[fatData.length - 1];
    //console.log('latest FAT  :', latestFAT);



const BMI = ((latestWeight / height / height) * 10000).toFixed(2) ; // Calculate BMI
    
      if( BMI >= 30){
        fitResult = 'Obesity 🚴‍♀️🏃‍♂️🧘‍♂️🥗';
      }else if( BMI >= 25 &&  BMI <= 29.9){

        fitResult = 'Overweight 🏃🧘‍♂️🥗';
      }else if(  BMI > 18.5 &&  BMI <= 24.9){

        fitResult = 'Good 😊 🧘‍♂️';
      }else if( BMI <= 18.5){

        fitResult = 'Underweight 🧘‍♂️🥗';
      }
      
     


 


/*
    // Map fitResult to corresponding health status and emojis
  const getStatusInfo = () => {
    switch (fitResult.toLowerCase()) {
      case 'obesity 🚴‍♀️🏃‍♂️🧘‍♂️🥗':
        return { status: 'Obesity', emoji: '🚴‍♀️🏃‍♂️🧘‍♂️🥗', style: statusStyles.obesity };
      case 'good 😊 🧘‍♂️':
        return { status: 'Good', emoji: '😊 🧘‍♂️', style: statusStyles.good };
      case 'overweight 🏃🧘‍♂️🥗':
        return { status: 'Overweight', emoji: '🏃🧘‍♂️🥗', style: statusStyles.overweight };
      case 'underweight 🧘‍♂️🥗':
        return { status: 'Underweight', emoji: '🧘‍♂️🥗', style: statusStyles.underweight };
      default:
        return { status: 'Unknown', emoji: '❓', style: {} };
    }
  };

  // Get status info based on fitResult
  const { status, emoji, style } = getStatusInfo();
*/


 // Function to get status information based on BMI
const getStatusInfo = (fitResult, BMI) => {
  let status;
  let emoji;
  let style;
  let suggestion;

  // Determine status, emoji, and style based on BMI range
  if (BMI >= 30) {
    status = 'Obesity';
    emoji = '🚴‍♀️🏃‍♂️🧘‍♂️🥗';
   // style = { color: 'red' }; // Example style for obesity
    style = { backgroundColor: '#ff6347', color: '#fff' };
    // Suggestion for obesity
    suggestion = [
      'Engage in regular physical activity such as cycling, running, or yoga.',
      'Follow a balanced diet rich in fruits, vegetables, and whole grains.',
      'Consult a healthcare professional for personalized advice and support.'
    ];
  } else if (BMI >= 25) {
    status = 'Overweight';
    emoji = '🏃🧘‍♂️🥗';
    style = { backgroundColor: '#ffa500', color: '#fff' }; // Example style for overweight
    // Suggestion for overweight
    suggestion = [
      'Incorporate regular exercise into your routine, focusing on cardio and strength training.',
      'Adopt a healthy eating plan with reduced calorie intake and portion control.',
      'Seek guidance from a healthcare provider to develop a sustainable weight loss plan.'
    ];
  } else if (BMI >= 18.5) {
    status = 'Normal Weight';
    emoji = '😊 🧘‍♂️';
    style = { backgroundColor: '#32cd32', color: '#fff' };  // Example style for normal weight
    // Suggestion for normal weight
    suggestion = [
      'Maintain your healthy lifestyle by staying physically active and eating a balanced diet.',
      'Continue monitoring your weight and overall health regularly.',
      'Stay hydrated and get enough rest to support your well-being.'
    ];
  } else {
    status = 'Underweight';
    emoji = '🧘‍♂️🥗';
    style = { backgroundColor: '#4169e1', color: '#fff' };// Example style for underweight
    // Suggestion for underweight
    suggestion = [
      'Consume a nutrient-dense diet with a focus on energy-dense foods.',
      'Include strength-building exercises to build muscle mass.',
      'Consult with a healthcare professional to address any underlying health concerns.'
    ];
  }

  return { status, emoji, style, suggestion };
};

const { status, emoji, style, suggestion } = getStatusInfo(fitResult, BMI);

/*
// HealthStatus component to render status and suggestions
function HealthStatus({ status, emoji, style, suggestion }) {
  return (
    <div style={{ padding: '10px', borderRadius: '5px', ...style }}>
      <h3>{status}</h3>
      <p>{emoji}</p>
      <div style={{ marginTop: '10px' }}>
        <h4>Suggestions:</h4>
        <ul style={{ listStyleType: 'none', padding: '0' }}>
          {suggestion && suggestion.map((sugg, index) => (
            <li key={index} style={{ marginBottom: '5px' }}>
              {index + 1}. {sugg}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

*/

 // HealthStatus component to render status and suggestions
function HealthStatus({ status, emoji, style, suggestion }) {
  return (
    <div style={{ padding: '20px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', ...style }}>
      <h3>{status}</h3>
      <p style={{ fontSize: '24px' }}>{emoji}</p>
      <div style={{ marginTop: '20px', textAlign: 'justify' }}>
        <h4 style={{ marginBottom: '10px', color: '#fffff' }}>Suggestions:</h4>
        <ul style={{ listStyleType: 'none', padding: '0', color: '#ffff', textAlign: 'left' }}>
          {suggestion && suggestion.map((sugg, index) => (
            <li key={index} style={{ marginBottom: '5px', fontSize: '16px', paddingLeft: '20px' }}>
              - {sugg}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}



  /*


      <p>Height : {userHeight}</p>
      <p>Latest Weight : {latestWeight}</p>
      <p>Latest FAT : {latestFAT}</p>
      <p>Latest BMI : {BMI}</p>

  <h3>{status}</h3>
      <p>{emoji}</p>

  */

    return (
      <div style={{ padding: '10px', borderRadius: '5px', ...style }}>
    

      <HealthStatus status={status} emoji={emoji} style={style} suggestion={suggestion} />
  
 
 

    </div>




    );
  }

  function MemberReport({ userProfile, posts, data }) {
    const [selectedRange, setSelectedRange] = useState(30); // Default to 30 days
    const [filteredData, setFilteredData] = useState(null);
  
    // Function to calculate the start and end dates based on the selected range
    const calculateDateRange = () => {
      const endDate = new Date();
      const startDate = new Date(endDate);
      startDate.setDate(endDate.getDate() - selectedRange);
      return { startDate, endDate };
    };
  
    // Update filtered data and x-axis labels whenever selected range changes
    useEffect(() => {
      const { startDate, endDate } = calculateDateRange();
      const filteredLabels = [];
      const filteredDatasets = data.datasets.map(dataset => {
        const filteredData = [];
        dataset.data.forEach((value, index) => {
          const entryDate = new Date(data.labels[index]);
          if (entryDate >= startDate && entryDate <= endDate) {
            filteredData.push(value);
            const dateString = entryDate.toISOString().split('T')[0];
            if (!filteredLabels.includes(dateString)) {
              filteredLabels.push(dateString);
            }
          }
        });
        return {
          ...dataset,
          data: filteredData
        };
      });
      const filteredData = {
        labels: filteredLabels,
        datasets: filteredDatasets
      };
      setFilteredData(filteredData);
    }, [selectedRange, data]);
  
    // Function to handle slider change
    const handleSliderChange = (e) => {
      setSelectedRange(parseInt(e.target.value));
    };
  
    // Function to handle dropdown change
    const handleDropdownChange = (e) => {
      setSelectedRange(parseInt(e.target.value));
    };
  
    return (
      <div style={{ width: "100%", maxWidth: "800px", height: "50vh", position: "relative", marginBottom: "1%", padding: "1%" }}>
        {/* Dropdown for predefined date ranges */}
        <div style={{ marginBottom: "10px" }}>
          <label htmlFor="range">Select Range:</label>
          <select id="range" name="range" value={selectedRange} onChange={handleDropdownChange}>
            <option value={30}>30 days</option>
            <option value={90}>90 days</option>
            <option value={180}>180 days</option>
            <option value={360}>360 days</option>
          </select>
        </div>
  
        {/* Slider for dynamically selecting date range */}
        <div style={{ marginBottom: "10px" }}>
          <input type="range" min="30" max="360" value={selectedRange} step={60} onChange={handleSliderChange} />
        </div>
  
        {/* Line Chart */}
        {filteredData && (
          <Line
            data={filteredData}
            options={{
              responsive: true,
              maintainAspectRatio: true,
              title: { text: "DAILY WEIGHT TREND", display: true },
              scales: {
                yAxes: [{
                  scaleLabel: {
                    display: true,
                    labelString: 'Weight(kg)'
                  }
                }],
                xAxes: [{
                  type: 'time',
                  time: {
                    unit: 'day',
                    displayFormats: {
                      day: 'MMM D'
                    }
                  },
                  scaleLabel: {
                    display: true,
                    labelString: 'Dates'
                  },
                  ticks: {
                    maxTicksLimit: 10 // Limiting the maximum number of ticks to avoid clutter
                  }
                }]
              }
            }}
          />
        )}
      </div>




    );
  }

function MemberProfile({type,message,id}){

  return (

<div className='container' >

<SelfUserProfileUpdateForm id={""+id}

onSubmit={(fields) => {

// Example function to trim all string inputs '8986c6bf-3318-40a0-908c-32097041de45'
//alert(fields.company)
const updatedFields = {}

Object.keys(fields).forEach(key => {

if (typeof fields[key] === 'string') {

updatedFields[key] = fields[key].trim()


} else {

updatedFields[key] = fields[key]

}
})
//alert(updatedFields['name']) 



return updatedFields



}}


onSuccess={() => {

//setShowForm(false) // Hide the form



alert(" Profile Update Submitted ")




}}


/>

</div>



);


}




// Define the createUserProfile mutation
const createUserProfileMutation = /* GraphQL */ `
  mutation CreateUserProfile(
    $input: CreateUserProfileInput!
    $condition: ModelUserProfileConditionInput
  ) {
    createUserProfile(input: $input, condition: $condition) {
      id
      email
      name
      dob
      gender
      height
      company
      mobile
      dietstatus
      diet
      ptstatus
      selfishsstatus
      profile
      breakfast
      lunch
      esnacks
      dinner
      dietaryadvice
      plan
      diettype
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

function MemberCreateProfile({ type, message, id, myemail }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showForm, setShowForm] = useState(true);

  const handleSubmit = async (fields) => {
    try {
      if (isSubmitting) return;
      setIsSubmitting(true);

      fields.email = myemail;
      const updatedFields = {};

      Object.keys(fields).forEach(key => {
        updatedFields[key] = typeof fields[key] === 'string' ? fields[key].trim() : fields[key];
      });

      const response = await API.graphql(graphqlOperation(createUserProfileMutation, { input: updatedFields }));

      if (response && response.data && response.data.createUserProfile) {
        alert("Profile Submitted");
        window.location.reload(); // Refresh the page after successful submission
      } else {
        throw new Error("Error submitting profile");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setIsSubmitting(false);
    }
  };

  return (
    <div className='container'>
      {showForm && (
        <UserProfileCreateForm
          name="UserProfileCreateForm"
          overrides={{
            'email': { 'isReadOnly': true, 'value': myemail }
          }}
          onSubmit={handleSubmit}
        />
      )}
    </div>
  );
}






function refreshPage(value){

if(value==="true")
{
 
  
  
}

}


function updateProfile(id, newName, newCompany, userProfile) {
  const updateProfiles = userProfile.map((profile) => {
      if (id == profile.id) {
          return { name: newName, company: newCompany };
      }

      return profile;
  });
  //setEmployees(updatedEmployees);
}

 



function alertHandler(type,message){

  return (

<Alert
  variation="success"
  isDismissible={false}
  hasIcon={true}
  heading={type}
  >
  {message}
</Alert>


  );
}

function errorHandler(type,message){

  return (

<Alert
  variation="error"
  isDismissible={false}
  hasIcon={true}
  heading={type}
  >
  {message}
</Alert>


  );
}

 