import React, { useState, useEffect , Component  } from "react";
import ReactDom from 'react-dom';

import { Auth, Amplify, API, DataStore, graphqlOperation } from 'aws-amplify'
import { withAuthenticator } from "@aws-amplify/ui-react";

import config from "../../aws-exports";



// import query definition
import { listPosts, getPost , ListUserProfiles, getUserProfile} from "../../graphql/queries";
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import * as subscriptions from '../../graphql/subscriptions';


import {
    DataGrid,
    GridCallbackDetails,
    GridEventListener,
    GridRowParams,
    GridSelectionModel,
  } from "@mui/x-data-grid";


import Radio from "@mui/material/Radio";

import "@aws-amplify/ui-react/styles.css";

import './UserHome.css';

import { NavBar } from '../../ui-components'
import { Post, UserProfile } from "../../models";

import { Selection, Form, Button, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';


import { ID, valuesEqual } from "@aws-amplify/datastore/lib-esm/util";
import { getValueFromValueOptions } from "@mui/x-data-grid/components/panel/filterPanel/filterPanelUtils";

import Table from 'react-bootstrap/Table';
import { isHomeOrEndKeys } from "@mui/x-data-grid/utils/keyboardUtils";
import { appBarClasses } from "@mui/material";

import { MDBContainer } from "mdbreact";
import { Pie } from "react-chartjs-2";
import { faker } from '@faker-js/faker';


import {
  BrowserRouter as Router,
  Routes,
  Route,
  Redirect,
} from "react-router-dom";


import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { Line } from 'react-chartjs-2';

import { ModelSortPredicateCreator , SortDirection, Predicates} from "@aws-amplify/datastore";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);





export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' 
    },
    title: {
      display: true,
      text: 'Chart.js Line Chart',
    },
  },
};


 


// npm install react-bootstrap bootstrap

const rows = [
    { id: 1, title: "ISHD Demo grid 1", content: "ISHD Demo grid content 1" },
    { id: 2, title: "ISHD Demo grid 1", content: "ISHD Demo grid content 2" }
  ];

  let radioChecked = [rows[0].id];

  const columns = [
    {
      field: "radiobutton",
      headerName: "",
      width: 100,
      sortable: false,
      renderCell: (params) => (
        <Radio checked={radioChecked[0] === params.id} value={params.id} />
      )
    },
    {
      field: "id",
      headerName: "ID"
    },
    {
      field: "name",
      headerName: "Name",
      width: 450
    },

    {
      field: "weight",
      headerName: "Weight",
      width: 150
    },

    {
      field: "bmi",
      headerName: "BMI",
      width: 150
    },
    {
      field: "fat",
      headerName: "FAT",
      width: 150
    }
  ];


async function description(){
    try {
       const allPostData = await API.graphql({ query: queries.listPosts });
        console.log('Posts Query successfully!', JSON.stringify(allPostData, 2));
      } catch (error) {
        console.log('Error retrieving posts', error);
      }
}





 


Amplify.configure(config);


function UserHome({ signOut, user }) {

  


    const [selectionModel, setSelectionModel] = React.useState(radioChecked);
    radioChecked = selectionModel;
  
    const selectedRow = rows.filter((item) => {
      return item.id === selectionModel[0];
    });


    

   // const [chartData, setChartData ] = useState([]);
   const timestamp = new Date().getTime();

const [posts, setPosts  ] = useState([]);

const [userProfile, setProfile  ] = useState([]);


  useEffect(() => {
    fetchPosts();
    fetchProfile();
  }, []);


  async function fetchProfile() {
    try {
      
      const userEmail =  user.attributes.email;


     const userProfiles = await API.graphql(graphqlOperation( queries.listUserProfiles, {filter: {email: {eq: userEmail}}}));

     const userProfilesData = userProfiles.data.listUserProfiles.items;
      
     //const userGroup =   user.attributes.sub;

     //const userProfilesDataEmail = JSON.parse( JSON.stringify ( userProfilesData.map(({email}) => email)));

     //const userProfilesDataName = JSON.parse( JSON.stringify ( userProfilesData.map(({company}) => company)));
     
     //console.log("  user Profile Emails = > "+ JSON.stringify(userProfilesDataEmail) );
     
     //console.log("  user Profile Name = > "+ JSON.stringify(userProfilesDataName) );



     setProfile(userProfilesData);


    } catch (err) {
      console.log({ err });
    }

    
  }


  async function fetchPosts() {
    try {
      
      const userEmail =  user.attributes.email;

        const queryParams = {
            sort: {
              direction: 'asc',
              field: 'createdAt'
            }
          };
          
          var today = new Date();

          const curTime = today.getFullYear()+"-"+today.getMonth+"-"+today.getDay+" "+today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();


    // const postData = await API.graphql({ query: queries.listPosts});


       //const postData = await API.graphql(graphqlOperation( listPosts) );

     // const postData = await API.graphql(graphqlOperation( listPosts, {filter: {email: {eq: "contact@ivssofttech.com"}}} )) ;
       
   
     console.log("  user.attributes.email userEmail = > "+userEmail);


     const postData = await API.graphql(graphqlOperation( listPosts, {filter: {email: {eq: userEmail}}, createdAt: {gt: curTime}} )) ; 

     const data =postData.data.listPosts.items;
   




     // const postData = await DataStore.query( Post );
      
     

    /* data = data.sort((a, b) => {
        if (a.createdAt < b.createdAt) {
          return -1;
        }
      });

*/
      setPosts(data);

  
    } catch (err) {
      console.log({ err });
    }

    
  }



const labels = JSON.parse( JSON.stringify ( posts.map(({createdAt}) => new Date(createdAt).toLocaleString(
    "en-US",
      {
        month: "short",
        day: "2-digit",
        year: "numeric",
        hour: '2-digit',
         minute: '2-digit',
      }
  ))) );

const data = {
  labels,
  datasets: [
    {
      label: 'Weight(kg)',
      title: { text: "DAILY WEIGHT TREND", display:true },
      data: (posts.map((post) => JSON.parse("["+post.content+"]")
      .map(item => 
        item.nameValuePairs.Weight
      ))).toLocaleString('') .split(',')

      
      
      ,
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',

    },

    {
        label: 'BMI',
        title: { text: "DAILY WEIGHT TREND", display:true },
        data: (posts.map((post) => JSON.parse("["+post.content+"]")
        .map(item => 
          item.nameValuePairs.BMI
        ))).toLocaleString('') .split(','),
        borderColor: 'rgb(120, 120, 242)',
        backgroundColor: 'rgba(120, 120, 242, 0.5)',
      },

      {
        label: 'FAT',
        title: { text: "DAILY WEIGHT TREND", display:true },
        data:  (posts.map((post) => JSON.parse("["+post.content+"]")
        .map(item => 
          item.nameValuePairs.FAT
        ))).toLocaleString('') .split(','),
        borderColor: 'rgb(255, 132, 99)',
        backgroundColor: 'rgba(255, 132, 99, 0.5)',
      },
  ],
}
 
console.log(data);

 
const dataGrid = JSON.parse( JSON.stringify ( posts.map(({ "name":name}) => name)) );     

const dataGridValue  =JSON.parse("["+posts.map(({ content}) => content)+"]");
 

// console.log(" nameValuePairsData => "+ nameValuePairsData);

// console.log(" dataGridValue => "+ dataGridValue);


  return (

    

    <>
      <NavBar width="100%" marginBottom='20px'   

        overrides={{  
          
          Button31632483: { onClick: async () => { alert("Home ") } } ,

          Button33572472: { onClick: async () => { alert("Profile ") } } ,

          Button33572473: { onClick: async () => { signOut() } }
      
          }}
      />


    <div class="App">
        <p></p>

    <h1>ISHS Fitness Dashboard </h1>

    <h3> Hello  {  userProfile.map(({company}) => company)}  !</h3>

    {user.attributes.email}

    {userProfile.map(({name}) => name) }
    {userProfile.map(({dob}) => dob) }
    {userProfile.map(({height}) => height) }

<div style={{width:"120vh",height:"50vh",position:"relative", marginBottom:"1%", padding:"1%"}} > 
   
    <Line 
    
  
        
        options = {{
          responsive: true,
          maintainAspectRatio: true ,
        
          title: { text: "DAILY WEIGHT TREND", display:true },
          scales: {
              yAxes: [{
                  scaleLabel: {
                      display: true,
                      labelString: 'Weight(kg)'
                  }
              }],
              xAxes: [{
                  scaleLabel: {
                      display: true,
                      labelString: 'Dates'
                  }
              }]
          }        
     
         

        }}


      data={data} 


        />

  </div>

    <div>

    <Table striped bordered hover variant="light" responsive size="sm" >
           <thead>
               <tr>
               <th>Created At</th>
               <th>Name</th>
               <th>Weight</th>
               <th>BMI</th>
               <th>FAT</th>
               </tr>
           </thead>
           <tbody>

             
      

            {posts.map((post) =>

           <tr> 
             
             <td> {post.createdAt}  </td>  
 
            <td> {post.name} </td> 
          
            <td> { 
            
              JSON.parse("["+post.content+"]")
              .map(item => 
              item.nameValuePairs.Weight
              )

            } </td>  

                <td> { 
            
              JSON.parse("["+post.content+"]")
              .map(item => 
              item.nameValuePairs.BMI
              )

            } </td>  


                <td> { 
            
              JSON.parse("["+post.content+"]")
              .map(item => 
              item.nameValuePairs.FAT
              )

            } </td>     
            
            </tr>

        )}
             
                    
           </tbody>
      </Table>
             

    </div>
 
        
      {
          
       
      }   
        
    

      


    </div>     




    </>
  );
}






export default withAuthenticator(UserHome);
 
