import React from 'react';
import './ChatBot.css';

const ChatBot = ({ onClose }) => {
    const botSecret = 'XfPr3DjKN5E.P4lHNrCIixxQw954DmuLWEe6PoIn8OYZqOhkZLNpndI'; // Replace with your actual bot secret
    const botUrl = `https://webchat.botframework.com/embed/ishsknwlang-bot?s=${botSecret}`;

    return (
        <div className="chatbot-container">
            <div className="chatbot-header">
                <h2>IVS Health FAQ Bot</h2>
                <button className="close-button" onClick={onClose}>X</button>
            </div>
            <div className="chatbot-body">
                <iframe
                    title="IVS Health FAQ Bot"
                    src={botUrl}
                    className="chatbot-iframe"
                ></iframe>
            </div>
        </div>
    );
};

export default ChatBot;
