import React from 'react';
import people from '../../assets/people.png';
//import ishsthumb from '../../assets/ishsthumb.png';
import ishsthumb from '../../assets/ISHS-banner3.png';
//import ishsthumb from '../../assets/ISHS-banner2.png';
//import ishsthumb from '../../assets/ISHS-Poster-Latest.png';

import { Flex, Link, useAuthenticator, useTheme } from "@aws-amplify/ui-react";

import "../../styles.css";

import { UserHome } from "../../UserHome";
import { SignInFooter } from "../../SignInFooter";

import YoutubeEmbed from "../../YouTubeEmbed";

//import { UserHomePage } from '../containers/userhome/UserHome';


import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet
} from "react-router-dom";

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import Root from "../../routes/root";

import App from "../../App";


//import {UserHome}  from  '../../UserHome';

import UserHomePage from '../../containers/userhome/UserHome';

import Profile from '../../ui-components/UserProfileUpdateForm';

import './header.css';

import { Selection, Form, Button, FormGroup, FormControl, ControlLabel, Navbar, Nav, Container} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';


const router = createBrowserRouter([
  {
    path: "/",
    element: <div>  Hello ISHS !</div>,
    element: <Root />,
  },
]);

 

const Header = ({ signOut, user }) => (



  <div className="ishs__header section__padding" id="home">
     <Navbar expand="lg" bg="dark" variant="dark" className="mb-4 d-lg-none">
      <Container>
        <Navbar.Brand href="#home">ISHS Fitness Partner</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/ishs">Dashboard</Nav.Link>
            <Nav.Link href="/ishs">My Account</Nav.Link>
            <Nav.Link href="https://www.ivssofttech.com" target="_blank">About</Nav.Link>
            <Nav.Link href="https://www.ivssofttech.com/shop" target="_blank">Shop</Nav.Link>
            
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    <div className="ishs__header-content">
   
 
      <div className=" ishs__header-content ishs__header-content__input has-small-icon-size has-icon-color is-content-justification-center is-layout-flex wp-container-1">    
  
          <div className="  wp-block-social-links has-small-icon-size has-icon-color has-icon-background-color is-style-default is-content-justification-center is-layout-flex wp-container-1">      
     
  
        <Container className="text-center">
      <h1 className="gradient__text">ISHS is your Fitness Partner!</h1>
      <h2>Be Happy!</h2>
      <h3>Stay Fit and Enjoy your Life!</h3>
      <Flex justifyContent="center" className="mt-3">
        <ul className="text-left">
          <li>Track Your Every Progress to Your Goal via ISHS Device!</li>
          <li>ISHS scale is the perfect tool to help you achieve your fitness Goal!</li>
          <li>Salient Features:</li>
          <ul>
            <li>Personalized Fitness Suggestions</li>
            <li>Customized Diet Plans</li>
            <li>Real-Time Body Composition Updates</li>
            <li>Understand Your Progress & Track Your Journey</li>
            <li>Daily Insights and Recommendations</li>
            <li>Monthly Consistency Reports</li>
            <li>Immediate Diet Plan Assignments</li>
          </ul>
        </ul>
      </Flex>
    </Container>
          </div>
      </div>
  
 

    </div>


    <div className="ishs__header-image">

    <img src={ishsthumb} />

    </div>

  </div>
);

export default Header;
