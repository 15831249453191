/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Button, Flex, Text } from "@aws-amplify/ui-react";
export default function NavBar(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="20px"
      direction="row"
      width="1440px"
      height="unset"
      justifyContent="space-between"
      alignItems="center"
      position="relative"
      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
      padding="24px 32px 24px 32px"
      backgroundColor="rgba(123,97,255,0.94)"
      {...getOverrideProps(overrides, "NavBar")}
      {...rest}
    >
      <Flex
        gap="15px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-end"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Buttons31472773")}
      >
        <Button
          width="unset"
          height="unset"
          border="1px SOLID rgba(255,255,255,1)"
          shrink="0"
          size="default"
          isDisabled={false}
          variation="default"
          children="Home"
          {...getOverrideProps(overrides, "Button31632483")}
        ></Button>
      </Flex>
      <Flex
        gap="2px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Logo")}
      >
        <Text
          fontFamily="Inter"
          fontSize="20px"
          fontWeight="700"
          color="rgba(239,240,240,1)"
          textTransform="capitalize"
          lineHeight="24.204544067382812px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="ISHS"
          {...getOverrideProps(overrides, "ISHS")}
        ></Text>
      </Flex>
      <Flex
        gap="15px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-end"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Buttons33572471")}
      >
        <Button
          width="unset"
          height="unset"
          border="1px SOLID rgba(255,255,255,1)"
          shrink="0"
          size="default"
          isDisabled={false}
          variation="default"
          children="Profile"
          {...getOverrideProps(overrides, "Button33572472")}
        ></Button>
        <Button
          width="unset"
          height="unset"
          border="1px SOLID rgba(255,255,255,1)"
          shrink="0"
          size="default"
          isDisabled={false}
          variation="default"
          children="Sign Out"
          {...getOverrideProps(overrides, "Button33572473")}
        ></Button>
      </Flex>
    </Flex>
  );
}
