import React, { useState, useEffect , Component  } from "react";
import ReactDom from 'react-dom';

import Modal from 'react-bootstrap/Modal';
//import Button from 'react-bootstrap/Button';

import { Alert } from '@aws-amplify/ui-react';



import { Auth, Amplify, API, DataStore, graphqlOperation } from 'aws-amplify'
import { withAuthenticator, useAuthenticator, useTheme } from "@aws-amplify/ui-react";


import { MDBDataTable } from 'mdbreact';


//import { Flex, Link } from "@aws-amplify/ui-react";

import config from "./aws-exports";



import { Header } from "./Header";
import { Footer } from "./Footer";
import { SignInHeader } from "./SignInHeader";
import { SignInFooter } from "./SignInFooter";
import "./styles.css";

import { UserHomePage } from './containers/userhome/UserHome';

import {  Header as HeaderPage} from './containers';


 //import Calendar from "calendar-reactjs";

import moment from 'moment';

import Calendar from 'react-calendar';

import 'react-calendar/dist/Calendar.css';

 


import ErrorPage from "./error-page";

import Root from "./routes/root";

//import { UserProfileUpdateForm } from "./ui-components/UserProfileUpdateForm";

import {
  UserProfileUpdateForm 
 } from './ui-components';

 

// import query definition
import { listPosts, getPost , ListUserProfiles, getUserProfile} from "./graphql/queries";
import * as queries from './graphql/queries';
import * as mutations from './graphql/mutations';
import * as subscriptions from './graphql/subscriptions';

import { TaskContainer, TaskItem } from "./task";


import {
    DataGrid,
    GridCallbackDetails,
    GridEventListener,
    GridRowParams,
    GridSelectionModel,
  } from "@mui/x-data-grid";


import Radio from "@mui/material/Radio";

import "@aws-amplify/ui-react/styles.css";

import './App.css';

import { NavBar } from './ui-components'
import { Post, UserProfile } from "./models";

import { Selection, Form, Button, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';


import { ID, valuesEqual } from "@aws-amplify/datastore/lib-esm/util";
import { getValueFromValueOptions } from "@mui/x-data-grid/components/panel/filterPanel/filterPanelUtils";

import Table from 'react-bootstrap/Table';

import Pagination from 'react-bootstrap/Pagination';
import PageItem from 'react-bootstrap/PageItem';


import { isHomeOrEndKeys } from "@mui/x-data-grid/utils/keyboardUtils";
import { appBarClasses } from "@mui/material";

import { MDBContainer } from "mdbreact";
import { Pie } from "react-chartjs-2";
import { faker } from '@faker-js/faker';

import ChatBot from './ChatBot';

import { Footer as PageFooter, Header as PageHeader} from './containers';

import { Navbar } from './ui-components'
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Redirect,
    useSearchParams,
    
  } from "react-router-dom";

  import { useNavigate } from 'react-router-dom';



import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';

import { Line,Bar } from 'react-chartjs-2';

import { ModelSortPredicateCreator , SortDirection, Predicates} from "@aws-amplify/datastore";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  ArcElement,
  Legend
);



 

const ChartComponent = () => {
  const data = {
    labels: ['FAT', 'BMI', 'BMR', 'Visceral Fat', 'Bone Mass', 'Muscle Mass', 'Water', 'Protein', 'Obesity'],
    datasets: [
      {
        label: 'Low',
        data: [10, 18.5, 1425, 3, 2.5, 33.1, 45, 16, 0],
        backgroundColor: 'blue',
      },
      {
        label: 'Healthy',
        data: [20, 24.9, 1575, 13, 3.5, 39.1, 60, 20, 0],
        backgroundColor: 'green',
      },
      {
        label: 'High',
        data: [25, 29.9, 1700, 17, 4.0, 45, 70, 25, 0],
        backgroundColor: 'yellow',
      },
      {
        label: 'Obesity',
        data: [35, 35, 0, 20, 0, 0, 0, 0, 30],
        backgroundColor: 'red',
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Body Composition Ranges',
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  return <Bar data={data} options={options} />;
};





export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' 
    },
    title: {
      display: true,
      text: 'Chart.js Line Chart',
    },
  },
};


 


// npm install react-bootstrap bootstrap

const rows = [
    { id: 1, title: "ISHD Demo grid 1", content: "ISHD Demo grid content 1" },
    { id: 2, title: "ISHD Demo grid 1", content: "ISHD Demo grid content 2" }
  ];

  let radioChecked = [rows[0].id];

  const columns = [
    {
      field: "radiobutton",
      headerName: "",
      width: 100,
      sortable: false,
      renderCell: (params) => (
        <Radio checked={radioChecked[0] === params.id} value={params.id} />
      )
    },
    {
      field: "id",
      headerName: "ID"
    },
    {
      field: "name",
      headerName: "Name",
      width: 450
    },

    {
      field: "weight",
      headerName: "Weight",
      width: 150
    },

    {
      field: "bmi",
      headerName: "BMI",
      width: 150
    },
    {
      field: "fat",
      headerName: "FAT",
      width: 150
    }
  ];


async function description(){
    try {
       const allPostData = await API.graphql({ query: queries.listPosts });
        console.log('Posts Query successfully!', JSON.stringify(allPostData, 2));
      } catch (error) {
        console.log('Error retrieving posts', error);
      }
}





Amplify.configure(config);


export  function UserHome({ signOut, user }) {


  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [searchParams] = useSearchParams();

  const [date, setDate] = useState(new Date());

  


    const [selectionModel, setSelectionModel] = React.useState(radioChecked);
    radioChecked = selectionModel;
  
    const selectedRow = rows.filter((item) => {
      return item.id === selectionModel[0];
    });



    const [showChatBot, setShowChatBot] = useState(false);

    const handleOpenChatBot = () => {
        setShowChatBot(true);
    };

    const handleCloseChatBot = () => {
        setShowChatBot(false);
    };

    

   // const [chartData, setChartData ] = useState([]);
   const timestamp = new Date().getTime();

   // for the month name 
   const currentMonth =   moment(new Date().getTime()).format('MMMM') ;

   // for the current date  
   const currDate =   moment(new Date()).format('YYYY-MM-DD') ;
    


const [posts, setPosts  ] = useState([]);

const [postsGraph, setPostsGraph  ] = useState([]);

const [userProfile, setProfile  ] = useState([]);






  useEffect(() => {
    fetchPosts();
    fetchProfile();
  }, []);


  async function fetchProfile() {
    try {
      
      
     // const userEmail =  user.attributes.email;

      const userEmail= searchParams.get('email');



     const userProfiles = await API.graphql(graphqlOperation( queries.listUserProfiles, {filter: {email: {eq: userEmail}}}));

     //const userProfiles = await API.graphql(graphqlOperation( queries.listUserProfiles));


     const userProfilesData = userProfiles.data.listUserProfiles.items;
      
     //const userGroup =   user.attributes.sub;

     //const userProfilesDataEmail = JSON.parse( JSON.stringify ( userProfilesData.map(({email}) => email)));

     //const userProfilesDataName = JSON.parse( JSON.stringify ( userProfilesData.map(({company}) => company)));
     
     //console.log("  user Profile Emails = > "+ JSON.stringify(userProfilesDataEmail) );
     
     //console.log("  user Profile Name = > "+ JSON.stringify(userProfilesDataName) );



     setProfile(userProfilesData);


    } catch (err) {
      console.log({ err });
    }

    
  }


  async function fetchPosts() {
    try {
      
     // const userEmail =  user.attributes.email;

    //  const userEmail =  'contact@ivssofttech.com';

      const userEmail= searchParams.get('email');

        const queryParams = {
            sort: {
              direction: 'asc',
              field: 'createdAt'
            }
          };
          
          var today = new Date();

          const curTime = today.getFullYear()+"-"+today.getMonth+"-"+today.getDay+" "+today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();


    // const postData = await API.graphql({ query: queries.listPosts});


       //const postData = await API.graphql(graphqlOperation( listPosts) );

     // const postData = await API.graphql(graphqlOperation( listPosts, {filter: {email: {eq: "contact@ivssofttech.com"}}} )) ;
       

     const variables = {
      filter: {
        and: [
          { email: {  eq: userEmail } },
        //  { createdAt: { gt: curTime } }
        ]
      }
    };
    
    /*

 const variables = {
      filter: {
        email: {
          eq: userEmail
        },
        createdAt: {
          gt: curTime
        }
      }
    };

    */
    
   
     console.log("  user.attributes.email userEmail = > "+userEmail);


    // const postData = await API.graphql(graphqlOperation( listPosts, {filter: {email: {eq: userEmail}}, createdAt: {gt: curTime}} )) ; 

   //  const postData = await API.graphql({ query: queries.listPosts, variables: variables });

     const postData = await DataStore.query(Post, (c) => c.email.eq(userEmail), 
     
         {sort: (s) => s.createdAt(SortDirection.DESCENDING)} 
     
       //{sort: (s) => s.createdAt(SortDirection.ASCENDING)} 
     
     );

     
     const postDataGraph = await DataStore.query(Post, (c) => c.email.eq(userEmail), 
     
      //   {sort: (s) => s.createdAt(SortDirection.DESCENDING)} 
     
       {sort: (s) => s.createdAt(SortDirection.ASCENDING)} 
     
     );



    // console.log("post kola postData "+JSON.stringify(postData));

    // const postData = await API.graphql(graphqlOperation( listPosts) );

     const data =postData;

     const dataGraph =postDataGraph;
   
    // const data =postData.data.listPosts.items;

  //   console.log("post kola data "+JSON.stringify(data));


     // const postData = await DataStore.query( Post );
      
     

    /* data = data.sort((a, b) => {
        if (a.createdAt < b.createdAt) {
          return -1;
        }
      });

       const labelsCal = JSON.parse( JSON.stringify ( posts.map(({createdAt}) => moment(createdAt).format('YYYY-MM-DD'))) );

*/
      setPosts(data);

      setPostsGraph(dataGraph);

  
    } catch (err) {
      console.log({ err });
    }

    
  }

  const labelsCal =  JSON.parse( JSON.stringify ( posts.map(({createdAt}) => moment(createdAt).format('YYYY-MM-DD'))) );

  const lblCallength = labelsCal.length;

const labels = JSON.parse( JSON.stringify ( postsGraph.map(({createdAt}) => new Date(createdAt).toLocaleString(
    "en-US",
      {
        month: "short",
        day: "2-digit",
        year: "numeric",
        hour: '2-digit',
         minute: '2-digit',
      }
  ))) );

  labels.sort(function(a, b){
    return a - b 
});


  const resultslabelsCalDate = labelsCal.map(date => new Date(date));

  const mdates = resultslabelsCalDate;

  mdates.sort(function(a, b){
    return a - b 
});


const mdatesAftSrt = mdates.map(mdate => moment(new Date(mdate)).format('YYYY-MM-DD'));

console.log(" dates mdatesAftSrt kola "+mdatesAftSrt);




  function isDateInArray(needle, haystack) {
    for (var i = 0; i < haystack.length; i++) {
      if (needle.getTime() === haystack[i].getTime()) {
        return true;
      }
    }
    return false;
  }
  
  var dates = [
    new Date('October 1, 2016 12:00:00 GMT+0000'),
    new Date('October 2, 2016 12:00:00 GMT+0000'),
    new Date('October 3, 2016 12:00:00 GMT+0000'),
    new Date('October 2, 2016 12:00:00 GMT+0000')
  ];
  
  
  const uniqueDates = [];
  for (var i = 0; i < resultslabelsCalDate.length; i++) {
    if (!isDateInArray(resultslabelsCalDate[i], uniqueDates)) {
      uniqueDates.push(resultslabelsCalDate[i]);
    }
  }
  
  //console.log(uniqueDates);

 


 
const data = {
  labels,
  datasets: [
    {
      label: 'Weight(kg)',
      title: { text: "DAILY WEIGHT TREND", display:true },
      data: (postsGraph.map((post) => JSON.parse("["+post.content+"]")
      .map(item => 
        item.nameValuePairs.Weight
      ))).toLocaleString('') .split(',')

      
      
      ,
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',

    },

    {
        label: 'BMI',
        title: { text: "DAILY WEIGHT TREND", display:true },
        data: (postsGraph.map((post) => JSON.parse("["+post.content+"]")
        .map(item => 
          item.nameValuePairs.BMI
        ))).toLocaleString('') .split(','),
        borderColor: 'rgb(120, 120, 242)',
        backgroundColor: 'rgba(120, 120, 242, 0.5)',
      },

      {
        label: 'FAT',
        title: { text: "DAILY WEIGHT TREND", display:true },
        data:  (postsGraph.map((post) => JSON.parse("["+post.content+"]")
        .map(item => 
          item.nameValuePairs.FAT
        ))).toLocaleString('') .split(','),
        borderColor: 'rgb(255, 132, 99)',
        backgroundColor: 'rgba(255, 132, 99, 0.5)',
      },
  ],
}
 
//console.log(data);

 
const dataGrid = JSON.parse( JSON.stringify ( posts.map(({ "name":name}) => name)) );     

const dataGridValue  =JSON.parse("["+posts.map(({ content}) => content)+"]");

const [showForm, setShowForm] = useState(true);

const name = JSON.parse( JSON.stringify ( userProfile.map(({ "name":name}) => name)) );

// create initial state
const initialState = {
  email: JSON.parse( JSON.stringify ( userProfile.map(({ "email":email}) => email)) ),
  name: JSON.parse( JSON.stringify ( userProfile.map(({ "name":name}) => name)) ),
  dob: JSON.parse( JSON.stringify ( userProfile.map(({ "dob":dob}) => dob)) ),
  gender: JSON.parse( JSON.stringify ( userProfile.map(({ "gender":gender}) => gender)) ),
  height: JSON.parse( JSON.stringify ( userProfile.map(({ "height":height}) => height)) ),
  company: JSON.parse( JSON.stringify ( userProfile.map(({ "company":company}) => company)) ),
};

const isUpdated = false ;

/*
const [initialStateData, setInitialState  ] = useState([]);

setInitialState(initialState);
*/


 
//const { user } = useAuthenticator((context) => [context.user]);
const [isAdmin, setIsAdmin] = useState(false);
const [visible, setVisible] = useState(false);


const handleSuccess = (fields) => {
  alertHandler({
      type: 'success',
      message: 'Successfully submitted new item!'
  });
  setVisible(false);
};

const handleError = (fields, msg) => {
  alertHandler({
      type: 'error',
      message: `There was an issue submitting your new item: ${msg}`
  });
  setVisible(false);
};

 
const ii=0;


 
//const homePage = useNavigate();
//homePage('/');

//const adminPage = useNavigate();
//adminPage('/ishs');


// console.log(" nameValuePairsData => "+ nameValuePairsData);

// console.log(" dataGridValue => "+ dataGridValue);


 


const nextCalDays = [

  '2023-07-01',
  '2023-07-05',
  '2023-07-06',
  '2023-07-07',
  '2023-07-18',
  '2023-06-17',
  '2023-06-28',
  '2023-06-29'
  ];
  

  console.log(" nextCalDays kola :"+nextCalDays);

let newCalDateObject = [];
 

let currentMonthCalDateObject = [];
let e =0;

/* current month data only

mdatesAftSrt.forEach((day) => {
  if(currentMonth === moment(new Date(day)).format('MMMM')){
   // console.log(" current month dates"+moment(new Date(day)).format('YYYY-MM-DD') );
    currentMonthCalDateObject.push(moment(new Date(day)).format('YYYY-MM-DD'));
    e++;
  }
});

*/

mdatesAftSrt.forEach((day) => {
    currentMonthCalDateObject.push(moment(new Date(day)).format('YYYY-MM-DD'));
});

const uniqueCalDates = [];

currentMonthCalDateObject.map(day => {
    if (uniqueCalDates.indexOf(day) === -1) {
     // console.log(" uniqueCalDates => day kola :"+day);   
      uniqueCalDates.push(day)
    }
});

 
//uniqueCalDates.push('2023-08-08');

console.log("uniqueCalDates");
console.log(uniqueCalDates);

console.log("nextCalDays");
console.log(nextCalDays);


let postDataM = [];

//postDataM.push({createdat:'2023-07-09 14:15:39', name: 'Alok', weight: '96', bmi:'28', fat:'30'} );


posts.map(post => {
  
   // console.log(" uniqueCalDates => day kola :"+day);   
   postDataM.push({createdat: post.createdAt, name: post.name, 
    
    weight: JSON.parse("["+post.content+"]")
    .map(item => 
    item.nameValuePairs.Weight
    ), 
    
    bmi:JSON.parse("["+post.content+"]")
   .map(item => 
   item.nameValuePairs.BMI
   ), 
   fat:JSON.parse("["+post.content+"]")
   .map(item => 
   item.nameValuePairs.FAT
   ), 
   Muscle:JSON.parse("["+post.content+"]")
   .map(item => 
   item.nameValuePairs.Muscle
   ), 
   BMR:JSON.parse("["+post.content+"]")
   .map(item => 
   item.nameValuePairs.BMR
   ), 
   Bone:JSON.parse("["+post.content+"]")
   .map(item => 
   item.nameValuePairs.Bone
   ), 
   Water:JSON.parse("["+post.content+"]")
   .map(item => 
   item.nameValuePairs.Water
   ), 
   Protein:JSON.parse("["+post.content+"]")
   .map(item => 
   item.nameValuePairs.Protein
   )
  ,
  rowClass: 'low' 
  
  } );
  }
);


console.log("postDataM"+JSON.stringify(postDataM));


  const dataM = {
    columns: [
      {
        label: 'Created At',
        field: 'createdat',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Name',
        field: 'name',
        sort: 'asc',
        width: 270
      },
      {
        label: 'Weight(Kg)',
        field: 'weight',
        sort: 'asc',
        width: 200
      },
      {
        label: 'BMI',
        field: 'bmi',
        sort: 'asc',
        width: 200
      },
      {
        label: 'FAT(%)',
        field: 'fat',
        sort: 'asc',
        width: 200
      },
      {
        label: 'Muscle(%)',
        field: 'Muscle',
        sort: 'asc',
        width: 200
      },
      {
        label: 'BMR(Kcal/day)',
        field: 'BMR',
        sort: 'asc',
        width: 200
      },
      {
        label: 'Bone(Kg)',
        field: 'Bone',
        sort: 'asc',
        width: 200
      },
      {
        label: 'Water(%)',
        field: 'Water',
        sort: 'asc',
        width: 200
      },
      {
        label: 'Protein(%)',
        field: 'Protein',
        sort: 'asc',
        width: 200
      }

    ],
    rows: postDataM

    }; 
  

    const TableComponent = ({ posts }) => {
      // Function to determine row color based on BMI value
      const getRowColorClass = (bmi) => {
        if (bmi < 18.5) return 'low-bmi';
        if (bmi >= 18.5 && bmi <= 24.9) return 'healthy-bmi';
        if (bmi >= 25 && bmi <= 29.9) return 'high-bmi';
        return 'obesity-bmi';
      };
    
      const postDataM = posts.map(post => {
        const content = JSON.parse(`[${post.content}]`)[0].nameValuePairs;
    
        return {
          createdat: post.createdAt,
          name: post.name,
          weight: content.Weight,
          bmi: content.BMI,
          fat: content.FAT,
          BMR: content.BMR,
          Visceral: content.Visceral,
          Muscle: content.Muscle,
          Bone: content.Bone,
          Water: content.Water,
          Protein: content.Protein,
          bmiClass: getRowColorClass(content.BMI) // Add the bmiClass property
        };
      });
    
      const dataM = {
        columns: [
          { label: 'Created At', field: 'createdat', sort: 'asc', width: 150 },
          { label: 'Name', field: 'name', sort: 'asc', width: 270 },
          { label: 'Weight(Kg)', field: 'weight', sort: 'asc', width: 200 },
          { label: 'BMI', field: 'bmi', sort: 'asc', width: 200 },
          { label: 'FAT(%)', field: 'fat', sort: 'asc', width: 200 },
          { label: 'BMR(Kcal/day)', field: 'BMR', sort: 'asc', width: 200 },
          { label: 'Visceral FAT(%)', field: 'Visceral', sort: 'asc', width: 200 },
          { label: 'Muscle(%)', field: 'Muscle', sort: 'asc', width: 200 },
          { label: 'Bone(Kg)', field: 'Bone', sort: 'asc', width: 200 },
          { label: 'Water(%)', field: 'Water', sort: 'asc', width: 200 },
          { label: 'Protein(%)', field: 'Protein', sort: 'asc', width: 200 }
        ],
        rows: postDataM
      };
    
      return (
        <MDBDataTable
          striped
          bordered
          small
          responsive
          data={dataM}
          tbodyCustomRow={(row, index) => dataM.rows[index].bmiClass} // Apply custom class to rows
        />
      );
    };




    let userGender =  (JSON.stringify(userProfile.map(({gender}) => gender)) ).trim().replace(/^\["|"\]$/g, '');

  //  let areEqual = userGender.toUpperCase() === "FEMALE";

    const getMetricClass = (value, metric) => {
      switch (metric) {
        case 'BMI':
          if (value < 18.5) return 'low';
          if (value >= 18.5 && value <= 24.9) return 'healthy';
          if (value >= 25 && value <= 29.9) return 'high';
          if (value >= 30) return 'obesity';
          break;
        case 'FAT':
          if (userGender.toLowerCase() === 'male'){

          

            if (value < 10) return 'low';
            if ((value >= 10  && value < 20)) return 'healthy';
            if ((value >= 20 && value < 25)) return 'high';
            if (value >= 25 ) return 'obesity';

          }else{
            if (value < 20) return 'low';
            if ((value >= 20  && value < 30)) return 'healthy';
            if ((value >= 30 && value < 35)) return 'high';
            if (value >= 35 ) return 'obesity';
          
          }
            
          break;
        case 'BMR':
          if (value < 1425) return 'low';
          if (value >= 1425 && value <= 1575) return 'healthy';
          if (value > 1575) return 'high';
          break;
        case 'VisceralFat':
          if (value < 4) return 'low';
          if (value >= 4 && value <= 13) return 'healthy';
          if (value >= 14 && value <= 17) return 'high';
          if (value > 17) return 'obesity';
          break;
        case 'Muscle':
          if (value < 33.1) return 'low';
          if (value >= 33.1 && value <= 39.1) return 'healthy';
          if (value > 39.1) return 'high';
          break;
        case 'Bone':
          if (value < 2.5 || value < 3.0) return 'low';
          if ((value >= 2.5 && value <= 3.5) || (value >= 3.0 && value <= 4.0)) return 'healthy';
          if (value > 3.5 || value > 4.0) return 'high';
          break;
        case 'Water':
          if (value < 45) return 'low';
          if (value >= 45 && value <= 60) return 'healthy';
          if (value > 60) return 'high';
          break;
        case 'Protein':
          if (value < 16) return 'low';
          if (value >= 16 && value <= 20) return 'healthy';
          if (value > 20) return 'high';
          break;
        default:
          return '';
      }
    };

    
 const FitnessTableComponent = ({ posts }) => {
  if (posts.length === 0) {
    return <div>No data available</div>;
  }

  const sortedPosts = posts.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  const latestPost = sortedPosts[0];
  const content = JSON.parse(`[${latestPost.content}]`)[0].nameValuePairs;

  return (
    <table className="styled-table">
      <thead>
        <tr>
          <th>Metric</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
       <tr>
          <td>Name</td>
          <td>{latestPost.name}</td>
        </tr>
        <tr>
          <td>Created At</td>
          <td>{latestPost.createdAt}</td>
        </tr>
        <tr>
          <td>Weight(Kg)</td>
          <td>{content.Weight}</td>
        </tr>
        <tr>
          <td>BMI</td>
          <td className={getMetricClass(content.BMI, 'BMI')}>{content.BMI}</td>
        </tr>
        <tr>
          <td>FAT(%)</td>
          <td className={getMetricClass(content.FAT, 'FAT')}>{content.FAT}</td>
        </tr>
        <tr>
          <td>BMR(Kcal/day)</td>
          <td className={getMetricClass(content.BMR, 'BMR')}>{content.BMR}</td>
        </tr>
        <tr>
          <td>Visceral FAT(%)</td>
          <td className={getMetricClass(content.Visceral, 'VisceralFat')}>{content.Visceral}</td>
        </tr>
        <tr>
          <td>Muscle(%)</td>
          <td className={getMetricClass(content.Muscle, 'Muscle')}>{content.Muscle}</td>
        </tr>
        <tr>
          <td>Bone(Kg)</td>
          <td className={getMetricClass(content.Bone, 'Bone')}>{content.Bone}</td>
        </tr>
        <tr>
          <td>Water(%)</td>
          <td className={getMetricClass(content.Water, 'Water')}>{content.Water}</td>
        </tr>
        <tr>
          <td>Protein(%)</td>
          <td className={getMetricClass(content.Protein, 'Protein')}>{content.Protein}</td>
        </tr>
      </tbody>
    </table>
  );

};

    

  return (

    

    <>
      <NavBar width="100%" marginBottom='20px'   

        overrides={{  
          
          Button31632483: { onClick: async () =>  {"/ishs"} } ,

          Button33572472: { onClick: async () => {handleShow()} } ,

          Button33572473: { onClick: async () => "/"  },

          ChatBotButton: { onClick: handleOpenChatBot } // New button for chatbot
      
          }}
      />


    <div class="App">
    <p><a href="/ishs">Back to Dashboard </a></p>



    <h1>ISHS Fitness Dashboard </h1>

    <h3> Welcome {  userProfile.map(({name}) => name)} !</h3>


            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Profile</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                <MemberProfile  
                    id = {userProfile.map(({ "id":id}) => id)}
                    type = 'success'
                    message=  'Successfully submitted Profile Update!'
                 />
  
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


   

<div>



<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

 {/* o section */}
 <div style={{ width: '100%', alignItems: 'center' }}> {/* Set width to 100% */}
        {/* Render the MemberReport component */}

        <HealthReport
        userProfile= {userProfile}
        posts= {posts}
        data = {data}
        height= {userProfile.map(({height}) => height)}
        />
      </div>
       
       
       
       {/* Blank row */}
       <div style={{ height: '40px' }}></div> {/* Adjust height as needed */}


         {/* Diet Plan section */}
      <div style={{ width: '100%', alignItems: 'center' }}> {/* Set width to 100% */}
        {/* Render the MemberReport component */}


      <DietPlan
        userProfile= {userProfile}
        diet = {userProfile.map(({diet}) => diet)}
        breakfast = {userProfile.map(({breakfast}) => breakfast)}
        lunch = {userProfile.map(({lunch}) => lunch)}
        esnacks = {userProfile.map(({esnacks}) => esnacks)}
        dinner = {userProfile.map(({dinner}) => dinner)}
        diettype = {userProfile.map(({diettype}) => diettype)}
        plan = {userProfile.map(({plan}) => plan)}
        profileStatus = {userProfile.map(({profile}) => profile)}
        dietaryadvice= {userProfile.map(({dietaryadvice}) => dietaryadvice)}
        height= {userProfile.map(({height}) => height)}
    
        />
      </div>

       {/* Blank row */}
       <div style={{ height: '40px' }}></div> {/* Adjust height as needed */}

<div style={{ width: '70%', alignItems: 'center' }}> {/* Set width to 100% */}
 <h4>Latest Body Composition Fitness Status</h4>
 </div>
<div style={{ width: '100%', alignItems: 'center' }}> {/* Set width to 100% */}
<div className="App">
  <FitnessTableComponent posts={posts} />
  <div style={{ width: '100%', alignItems: 'center' }}> {/* Set width to 100% */}
  <h5><i>Legend</i></h5>
  <table className="styled-table">
<thead>          
     <tr>
          <th className="low">Low</th>
          <th className="healthy">Healthy</th>
          <th className="high">High</th>
          <th className="obesity">Obesity/Very High</th>
        </tr>
    </thead>    
    </table> 
 </div>

</div>
</div>


 
       {/* Blank row */}
       <div style={{ height: '40px' }}></div> {/* Adjust height as needed */}

      {/* First section */}
      <div style={{ width: '100%', alignItems: 'center' }}> {/* Set width to 100% */}
        {/* Render the MemberReport component */}
        <center>
        <b><h4>Fitness Progress Graph Report </h4></b>
        <MemberReport
        userProfile= {userProfile}
        posts= {posts}
        data = {data}
        />
        </center>
      </div>


      {/* Blank row */}
      <div style={{ height: '40px' }}></div> {/* Adjust height as needed */}
      <div className="app" style={{ width: '100%', alignItems: 'center' }}> {/* Set width to 100% */}
      <h4>Ideal Body Composition Ranges</h4>
      <table className="styled-table">
        <thead>
          <tr>
            <th>Metric</th>
            <th className="low">Low</th>
            <th className="healthy">Healthy</th>
            <th className="high">High</th>
            <th className="obesity">Obesity/Very High</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>FAT (%)</td>
            <td className="low">&lt; 10% (men), &lt; 20% (women)</td>
            <td className="healthy">10-20% (men), 20-30% (women)</td>
            <td className="high">20-25% (men), 30-35% (women)</td>
            <td className="obesity">&gt; 25% (men), &gt; 35% (women)</td>
          </tr>
          <tr>
            <td>BMI</td>
            <td className="low">&lt; 18.5</td>
            <td className="healthy">18.5 - 24.9</td>
            <td className="high">25 - 29.9</td>
            <td className="obesity">&gt; 30</td>
          </tr>
          <tr>
            <td>BMR</td>
            <td className="low">&lt; 1425 (Varies by individual)</td>
            <td className="healthy">1425 - 1575 (Varies by individual)</td>
            <td className="high">&gt; 1575 (Varies by individual)</td>
            <td className="obesity">NA</td>
          </tr>
          <tr>
            <td>Visceral Fat</td>
            <td className="low">1 - 3</td>
            <td className="healthy">4 - 13</td>
            <td className="high">14 - 17</td>
            <td className="obesity">&gt; 17</td>
          </tr>
          <tr>
            <td>Bone Mass (kg)</td>
            <td className="low">&lt; 2.5 (women), &lt; 3.0 (men)</td>
            <td className="healthy">2.5 - 3.5 (women), 3.0 - 4.0 (men)</td>
            <td className="high">&gt; 3.5 (women), &gt; 4.0 (men)</td>
            <td className="obesity">N/A</td>
          </tr>
          <tr>
            <td>Muscle Mass</td>
            <td className="low">&lt; 33.1% (Varies by individual)</td>
            <td className="healthy">33.1 - 39.1% (Varies by individual)</td>
            <td className="high">&gt; 39.1% (Varies by individual)</td>
            <td className="obesity">N/A</td>
          </tr>
          <tr>
            <td>Water (%)</td>
            <td className="low">&lt; 45%</td>
            <td className="healthy">45 - 60%</td>
            <td className="high">&gt; 60%</td>
            <td className="obesity">N/A</td>
          </tr>
          <tr>
            <td>Protein (%)</td>
            <td className="low">&lt; 16%</td>
            <td className="healthy">16 - 20%</td>
            <td className="high">&gt; 20%</td>
            <td className="obesity">N/A</td>
          </tr>
          <tr>
            <td>Obesity</td>
            <td className="low">Not applicable</td>
            <td className="healthy">Not applicable</td>
            <td className="high">Not applicable</td>
            <td className="obesity">BMI &gt; 30</td>
          </tr>
        </tbody>
      </table>
    </div>
    </div>

    {/* Second section */}
<div>
        <center>
          <p></p>
          <b><h4>Monthly Attendance Report</h4></b>
          <Calendar
              style={{ height: 500 }}
              //onChange={this.onChange}
            // value={this.state.date}
              tileClassName={({ date, view }) => {
                if(uniqueCalDates.find(x=>x===moment(date).format("YYYY-MM-DD"))){
                return  'highlight-present'
                }
                
              }}

              tileContent={({ date, view }) => {
                if(uniqueCalDates.find(x=>x===moment(date).format("YYYY-MM-DD"))){
                return  ' P'
                }
                
              }}


              tileDisabled={({ date }) => date.getDay() === 0}

              defaultActiveStartDate={new Date()}
              /*maxDate={new Date(2020, 1, 0)}</div>*/
              /*
              minDate={
                new Date()
              }
              */
            >
          </Calendar>
        </center>
      </div>
    </div>


     {/* Blank row */}

      {/* Blank row */}
   <div style={{ height: '40px' }}></div> {/* Adjust height as needed */}

     <div style={{ width: '100%', alignItems: 'center' }}> {/* Set width to 100% */}

    <div className="App">
      <h3>Daily Fitness Report</h3>
      <TableComponent posts={posts} />
    </div>

    </div>
  </div>



  <main>
                {!showChatBot && (
                    <button className="open-chatbot-button" onClick={handleOpenChatBot}>
                        Open IVS Health FAQ Bot
                    </button>
                )}
                {showChatBot && (
                    
                         <div className="chatbot-container">
                        <ChatBot onClose={handleCloseChatBot} />
                    </div>
                    
                
                )}
            </main>

    </>
  );
}






export default withAuthenticator(UserHome);




 
/*
export default withAuthenticator(UserHome, {
  components: {
    Header,
    HeaderPage,
    SignIn: {
      Header: SignInHeader,
      Footer: SignInFooter
    },
    Footer
  }
});

*/


function TodaysDate() {
  var tempDate = new Date();
  var date = tempDate.getFullYear() + '-' + (tempDate.getMonth()+1) + '-' + tempDate.getDate();
  const currDate = date;
  return (
    <p>{currDate}</p>
  );
}


function TodaysMonth() {
  var tempDate = new Date();
  var date = (tempDate.getMonth()+1);
  const currDate = date;
  return (
    <p>{currDate}</p>
  );
}

function TodaysYear() {
  var tempDate = new Date();
  var date = tempDate.getFullYear();
  const currDate = date;
  return (
    <p>{currDate}</p>
  );
}

// { date: '2023-07-09' , status: "present" },


function MyCalendar({mcalMe}){

console.log(" kola mcalMe "+mcalMe);

return (
 
 {mcalMe}  
  

 );

}



 

function DietPlan({userProfile, diet,breakfast,lunch,esnacks,dinner,diettype,plan,profileStatus,dietaryadvice,height}) {

  const colorCombination = [
    { backgroundColor: '#FFE4E1', color: '#FF5733', iconColor: '#FF5733' },
    { backgroundColor: '#FFDAB9', color: '#8B4513', iconColor: '#8B4513' },
    { backgroundColor: '#F0FFF0', color: '#228B22', iconColor: '#228B22' },
    { backgroundColor: '#E6E6FA', color: '#8A2BE2', iconColor: '#8A2BE2'  },
    { backgroundColor: '#f8ddcb', color: '#333333', iconColor: '#333333' },
    { backgroundColor: '#FFFACD', color: '#333333', iconColor: '#333333' },
    { backgroundColor: '#F0FFF0', color: '#228B22', iconColor: '#228B22' },
    { backgroundColor: '#E6E6FA', color: '#8A2BE2', iconColor: '#8A2BE2' },
    { backgroundColor: '#00FFFF', color: '#333333', iconColor: '#333333' },
    { backgroundColor: '#FF4500', color: '#333333', iconColor: '#333333' }
];

  const DietPlanComponent = () => (
      <div className="diet-plan">
          <h2 style={{ textAlign: 'center', marginBottom: '20px', color: '#FF5733' }}>Personalised Diet Plan</h2>
          <div className="meal" style={colorCombination[4]}>
              <h3>Diet Type :</h3>
              <p>{diettype}</p>
          </div>
          <div className="meal" style={colorCombination[5]}>
              <h3>Dietary Advice :</h3>
              <p>{dietaryadvice}</p>
          </div>
          <div className="meal" style={colorCombination[0]}>
              <h3><i className="fas fa-coffee"></i> Morning Breakfast:</h3>
              <p>{breakfast}</p>
          </div>
          <div className="meal" style={colorCombination[1]}>
              <h3><i className="fas fa-utensils"></i> Lunch:</h3>
              <p>{lunch}</p>
          </div>
          <div className="meal" style={colorCombination[2]}>
              <h3><i className="fas fa-apple-alt"></i> Evening Snacks:</h3>
              <p>{esnacks}</p>
          </div>
          <div className="meal" style={colorCombination[3]}>
              <h3><i className="fas fa-utensils"></i> Dinner:</h3>
              <p>{dinner}</p>
          </div>

      </div>
  );

    return (
      <div className="app">
        <DietPlanComponent />
      </div>
    );
  }


function HealthReport({ userProfile, posts, data, height }) {
    

  const statusStyles = {
    obesity: { backgroundColor: '#FF5733', color: '#FFFFFF' },
    good: { backgroundColor: '#66BB6A', color: '#FFFFFF' },
    overweight: { backgroundColor: '#FFA726', color: '#FFFFFF' },
    underweight: { backgroundColor: '#42A5F5', color: '#FFFFFF' }
  };


  let  fitResult = [];


 const userHeight = height ;

 const weight = "94";


  //console.log( posts)

//  console.log(data)


  // Assuming 'data' is your provided dataset
const weightDataset = data.datasets.find(dataset => dataset.label === 'Weight(kg)');
// Get the data array from the Weight Dataset
const weightData = weightDataset.data;
// Get the last or latest value from the weight data
const latestWeight = weightData[weightData.length - 1];
//console.log('Latest Weight:', latestWeight);

  // Assuming 'data' is your provided dataset
  const FATDataset = data.datasets.find(dataset => dataset.label === 'FAT');
  // Get the data array from the Weight Dataset
  const fatData = FATDataset.data;
  // Get the last or latest value from the weight data
  const latestFAT = fatData[fatData.length - 1];
  //console.log('latest FAT  :', latestFAT);



const BMI = ((latestWeight / height / height) * 10000).toFixed(2) ; // Calculate BMI
  
    if( BMI >= 30){
      fitResult = 'Obesity 🚴‍♀️🏃‍♂️🧘‍♂️🥗';
    }else if( BMI >= 25 &&  BMI <= 29.9){

      fitResult = 'Overweight 🏃🧘‍♂️🥗';
    }else if(  BMI > 18.5 &&  BMI <= 24.9){

      fitResult = 'Good 😊 🧘‍♂️';
    }else if( BMI <= 18.5){

      fitResult = 'Underweight 🧘‍♂️🥗';
    }
    
   





/*
  // Map fitResult to corresponding health status and emojis
const getStatusInfo = () => {
  switch (fitResult.toLowerCase()) {
    case 'obesity 🚴‍♀️🏃‍♂️🧘‍♂️🥗':
      return { status: 'Obesity', emoji: '🚴‍♀️🏃‍♂️🧘‍♂️🥗', style: statusStyles.obesity };
    case 'good 😊 🧘‍♂️':
      return { status: 'Good', emoji: '😊 🧘‍♂️', style: statusStyles.good };
    case 'overweight 🏃🧘‍♂️🥗':
      return { status: 'Overweight', emoji: '🏃🧘‍♂️🥗', style: statusStyles.overweight };
    case 'underweight 🧘‍♂️🥗':
      return { status: 'Underweight', emoji: '🧘‍♂️🥗', style: statusStyles.underweight };
    default:
      return { status: 'Unknown', emoji: '❓', style: {} };
  }
};

// Get status info based on fitResult
const { status, emoji, style } = getStatusInfo();
*/


// Function to get status information based on BMI
const getStatusInfo = (fitResult, BMI) => {
let status;
let emoji;
let style;
let suggestion;

// Determine status, emoji, and style based on BMI range
if (BMI >= 30) {
  status = 'Obesity';
  emoji = '🚴‍♀️🏃‍♂️🧘‍♂️🥗';
 // style = { color: 'red' }; // Example style for obesity
  style = { backgroundColor: '#ff6347', color: '#fff' };
  // Suggestion for obesity
  suggestion = [
    'Engage in regular physical activity such as cycling, running, or yoga.',
    'Follow a balanced diet rich in fruits, vegetables, and whole grains.',
    'Consult a healthcare professional for personalized advice and support.'
  ];
} else if (BMI >= 25) {
  status = 'Overweight';
  emoji = '🏃🧘‍♂️🥗';
  style = { backgroundColor: '#ffa500', color: '#fff' }; // Example style for overweight
  // Suggestion for overweight
  suggestion = [
    'Incorporate regular exercise into your routine, focusing on cardio and strength training.',
    'Adopt a healthy eating plan with reduced calorie intake and portion control.',
    'Seek guidance from a healthcare provider to develop a sustainable weight loss plan.'
  ];
} else if (BMI >= 18.5) {
  status = 'Normal Weight';
  emoji = '😊 🧘‍♂️';
  style = { backgroundColor: '#32cd32', color: '#fff' };  // Example style for normal weight
  // Suggestion for normal weight
  suggestion = [
    'Maintain your healthy lifestyle by staying physically active and eating a balanced diet.',
    'Continue monitoring your weight and overall health regularly.',
    'Stay hydrated and get enough rest to support your well-being.'
  ];
} else {
  status = 'Underweight';
  emoji = '🧘‍♂️🥗';
  style = { backgroundColor: '#4169e1', color: '#fff' };// Example style for underweight
  // Suggestion for underweight
  suggestion = [
    'Consume a nutrient-dense diet with a focus on energy-dense foods.',
    'Include strength-building exercises to build muscle mass.',
    'Consult with a healthcare professional to address any underlying health concerns.'
  ];
}

return { status, emoji, style, suggestion };
};

const { status, emoji, style, suggestion } = getStatusInfo(fitResult, BMI);

/*
// HealthStatus component to render status and suggestions
function HealthStatus({ status, emoji, style, suggestion }) {
return (
  <div style={{ padding: '10px', borderRadius: '5px', ...style }}>
    <h3>{status}</h3>
    <p>{emoji}</p>
    <div style={{ marginTop: '10px' }}>
      <h4>Suggestions:</h4>
      <ul style={{ listStyleType: 'none', padding: '0' }}>
        {suggestion && suggestion.map((sugg, index) => (
          <li key={index} style={{ marginBottom: '5px' }}>
            {index + 1}. {sugg}
          </li>
        ))}
      </ul>
    </div>
  </div>
);
}

*/

// HealthStatus component to render status and suggestions
function HealthStatus({ status, emoji, style, suggestion }) {
return (
  <div style={{ padding: '20px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', ...style }}>
    <h3>{status}</h3>
    <p style={{ fontSize: '24px' }}>{emoji}</p>
    <div style={{ marginTop: '20px', textAlign: 'justify' }}>
      <h4 style={{ marginBottom: '10px', color: '#fffff' }}>Suggestions:</h4>
      <ul style={{ listStyleType: 'none', padding: '0', color: '#ffff', textAlign: 'left' }}>
        {suggestion && suggestion.map((sugg, index) => (
          <li key={index} style={{ marginBottom: '5px', fontSize: '16px', paddingLeft: '20px' }}>
            - {sugg}
          </li>
        ))}
      </ul>
    </div>
  </div>
);
}



/*


    <p>Height : {userHeight}</p>
    <p>Latest Weight : {latestWeight}</p>
    <p>Latest FAT : {latestFAT}</p>
    <p>Latest BMI : {BMI}</p>

<h3>{status}</h3>
    <p>{emoji}</p>

*/

  return (
    <div style={{ padding: '10px', borderRadius: '5px', ...style }}>
  

    <HealthStatus status={status} emoji={emoji} style={style} suggestion={suggestion} />




  </div>




  );
}


function MemberReport({ userProfile, posts, data }) {
  const [selectedRange, setSelectedRange] = useState(30); // Default to 30 days
  const [filteredData, setFilteredData] = useState(null);

  // Function to calculate the start and end dates based on the selected range
  const calculateDateRange = () => {
    const endDate = new Date();
    const startDate = new Date(endDate);
    startDate.setDate(endDate.getDate() - selectedRange);
    return { startDate, endDate };
  };

  // Update filtered data and x-axis labels whenever selected range changes
  useEffect(() => {
    const { startDate, endDate } = calculateDateRange();
    const filteredLabels = [];
    const filteredDatasets = data.datasets.map(dataset => {
      const filteredData = [];
      dataset.data.forEach((value, index) => {
        const entryDate = new Date(data.labels[index]);
        if (entryDate >= startDate && entryDate <= endDate) {
          filteredData.push(value);
          const dateString = entryDate.toISOString().split('T')[0];
          if (!filteredLabels.includes(dateString)) {
            filteredLabels.push(dateString);
          }
        }
      });
      return {
        ...dataset,
        data: filteredData
      };
    });
    const filteredData = {
      labels: filteredLabels,
      datasets: filteredDatasets
    };
    setFilteredData(filteredData);
  }, [selectedRange, data]);

  // Function to handle slider change
  const handleSliderChange = (e) => {
    setSelectedRange(parseInt(e.target.value));
  };

  // Function to handle dropdown change
  const handleDropdownChange = (e) => {
    setSelectedRange(parseInt(e.target.value));
  };

  return (
    <div style={{ width: "100%", maxWidth: "800px", height: "50vh", position: "relative", marginBottom: "1%", padding: "1%" }}>
      {/* Dropdown for predefined date ranges */}
      <div style={{ marginBottom: "10px" }}>
        <label htmlFor="range">Select Range:</label>
        <select id="range" name="range" value={selectedRange} onChange={handleDropdownChange}>
          <option value={30}>30 days</option>
          <option value={90}>90 days</option>
          <option value={180}>180 days</option>
          <option value={360}>360 days</option>
        </select>
      </div>

      {/* Slider for dynamically selecting date range */}
      <div style={{ marginBottom: "10px" }}>
        <input type="range" min="30" max="360" value={selectedRange} step={60} onChange={handleSliderChange} />
      </div>

      {/* Line Chart */}
      {filteredData && (
        <Line
          data={filteredData}
          options={{
            responsive: true,
            maintainAspectRatio: true,
            title: { text: "DAILY WEIGHT TREND", display: true },
            scales: {
              yAxes: [{
                scaleLabel: {
                  display: true,
                  labelString: 'Weight(kg)'
                }
              }],
              xAxes: [{
                type: 'time',
                time: {
                  unit: 'day',
                  displayFormats: {
                    day: 'MMM D'
                  }
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Dates'
                },
                ticks: {
                  maxTicksLimit: 10 // Limiting the maximum number of ticks to avoid clutter
                }
              }]
            }
          }}
        />
      )}
    </div>




  );
}





function MyCalendarOLD({mlabelsCal}){


  var tempDate = new Date();
  var date = tempDate.getFullYear() + '-' + (tempDate.getMonth()+1) + '-' + tempDate.getDate();
  const currDate = date;

return (
 
  mlabelsCal.map(lblDate =>
" { date: \""+moment(new Date(lblDate)).format('YYYY-MM-DD')+"\" , status: \"present\" } \, "
    )

 );

}




function MemberProfile({type,message,id}){

  return (

<div className='container' >

<UserProfileUpdateForm id={""+id}

onSubmit={(fields) => {

// Example function to trim all string inputs '8986c6bf-3318-40a0-908c-32097041de45'
//alert(fields.company)
const updatedFields = {}

Object.keys(fields).forEach(key => {

if (typeof fields[key] === 'string') {

updatedFields[key] = fields[key].trim()


} else {

updatedFields[key] = fields[key]

}
})
//alert(updatedFields['name']) 



return updatedFields



}}


onSuccess={() => {

//setShowForm(false) // Hide the form



alert(" Profile Update Submitted ")





}}


/>

</div>



);


}






function updateProfile(id, newName, newCompany, userProfile) {
  const updateProfiles = userProfile.map((profile) => {
      if (id == profile.id) {
          return { name: newName, company: newCompany };
      }

      return profile;
  });
  //setEmployees(updatedEmployees);
}

 



function alertHandler(type,message){

  return (

<Alert
  variation="success"
  isDismissible={false}
  hasIcon={true}
  heading={type}
  >
  {message}
</Alert>


  );
}

function errorHandler(type,message){

  return (

<Alert
  variation="error"
  isDismissible={false}
  hasIcon={true}
  heading={type}
  >
  {message}
</Alert>


  );
}

 
