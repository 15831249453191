import React from 'react';

//import { Footer, Blog, Possibility, Features, WhatGPT3, Header } from './containers';
//import { CTA, Brand, Navbar } from './components';

import { Footer, Header } from './containers';

import { Navbar } from './ui-components'
import { Selection, Form, Button, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';

import {
    BrowserRouter as Router,
    Routes,
    Redirect,
  } from "react-router-dom";

  import {
    createBrowserRouter,
    Route,
    RouterProvider,
  } from "react-router-dom";

import { Flex, Link, useAuthenticator, useTheme } from "@aws-amplify/ui-react";

import './App.css';

import "./styles.css";

import { UserHomePage } from './containers/userhome/UserHome';

import {  Header as HeaderPage} from './containers';

import { UserHome } from "./UserHome";

import ErrorPage from "./error-page";

import Root from "./routes/root";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    
  },
]);


 const Home = ({ signOut, user }) => (
  <div className="App">
    <div className="gradient__bg">
    <Navbar 
    
    overrides={{  
          
        Button31632483: { onClick: async () => { alert("Home ") } } ,

        Button33572473: { onClick: async () => { signOut() } }
    
        }}
    
    />
 

 <Flex justifyContent="center" padding={`0 0 0`}>
<Header></Header>
</Flex>

<Flex justifyContent="center" padding={`0 0 0`}>
 

<Footer></Footer>

</Flex>


    </div>
 
  </div>
);

export default Home;
