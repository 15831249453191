import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import App from './App';

import Home from './Home';

//import App from './UserHome';

//import App from './Home';

import reportWebVitals from './reportWebVitals';

import { AmplifyProvider } from '@aws-amplify/ui-react'

import { studioTheme } from './ui-components'

import ErrorPage from "./error-page";

import Root from "./routes/root";


import {
    createBrowserRouter,
    Route,
    RouterProvider,
  } from "react-router-dom";



import '@aws-amplify/ui-react/styles.css'

import '@fortawesome/fontawesome-free/css/all.min.css';  
import 'bootstrap-css-only/css/bootstrap.min.css';  
import 'mdbreact/dist/css/mdb.css';

//https://maxcdn.bootstrapcdn.com/font-awesome/latest/css/font-awesome.min.css

//https://fonts.googleapis.com/css?family=Montserrat:400,700,200


import { Amplify } from 'aws-amplify'
import config from './aws-exports'
import { UserHome } from './UserHome';

Amplify.configure(config)


const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
      errorElement: <ErrorPage />,
      
    },

    {
    path: "/ishs",
    element: <App />,
    errorElement: <ErrorPage />,
  },

  {
    path: "/userhome",
    element: <UserHome />,
    errorElement: <ErrorPage />,
  },

  {
    path: "about",
    element: <div>About</div>,
  },


  ]);

  

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <AmplifyProvider theme={studioTheme}>

     <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>

    </AmplifyProvider>
   
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
