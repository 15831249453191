// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const DietType = {
  "TRADITIONAL": "TRADITIONAL",
  "VEGAN": "VEGAN",
  "MEDITERRANEAN": "MEDITERRANEAN",
  "KETO": "KETO",
  "PALEO": "PALEO",
  "LOWCARB": "LOWCARB",
  "INTERMITTENT": "INTERMITTENT",
  "GLUTENFREE": "GLUTENFREE",
  "DASH": "DASH",
  "FLEXITARIAN": "FLEXITARIAN",
  "PESCATARIAN": "PESCATARIAN",
  "LACTOSEFREE": "LACTOSEFREE",
  "VEGETARIAN": "VEGETARIAN"
};

const Plan = {
  "BASIC": "BASIC",
  "REGULAR": "REGULAR",
  "ADVANCE": "ADVANCE",
  "PREMIUM": "PREMIUM"
};

const Gender = {
  "MALE": "MALE",
  "FEMALE": "FEMALE"
};

const PostStatus = {
  "ACTIVE": "ACTIVE",
  "INACTIVE": "INACTIVE"
};

const DietStatus = {
  "ACTIVE": "ACTIVE",
  "INACTIVE": "INACTIVE"
};

const PtStatus = {
  "ACTIVE": "ACTIVE",
  "INACTIVE": "INACTIVE"
};

const SelfIshsStatus = {
  "ACTIVE": "ACTIVE",
  "INACTIVE": "INACTIVE"
};

const { UserGroup, UserProfile, Post } = initSchema(schema);

export {
  UserGroup,
  UserProfile,
  Post,
  DietType,
  Plan,
  Gender,
  PostStatus,
  DietStatus,
  PtStatus,
  SelfIshsStatus
};